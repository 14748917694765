import { Box, Typography } from "@mui/material";
import { Button } from "./Button";
import { useModalContext } from "../Contexts/ModalContext";
import { Makeup } from "../Interfaces/Makeup";
import { MakeupType } from "../Interfaces/Base";

export const MakeupSummary = ({ makeup }: { makeup: Makeup | null }) => {
  const { openModal, setModalData } = useModalContext();

  const handleSetupModalAndOpen = () => {
    setModalData(
      "Decision Details",
      makeup?.finalMakeup?.decision ?? "Final Decision Not Found.",
      makeup?.finalMakeup.comment ?? ""
    );
    openModal();
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {/* Torque Box */}
      <Box
        sx={{
          backgroundColor: "var(--parker-cyan)",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
          fontWeight: "bold",
          marginBottom: "1em",
          width: "calc(100% - 1em)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            variant='h6'
            sx={{ color: "white", textAlign: "center", width: "100%" }}
          >
            Torque
          </Typography>
        </Box>

        {/* Final */}
        <Box
          sx={{
            display: "flex",
            backgroundColor:
              makeup &&
              makeup.finalMakeup &&
              makeup.finalMakeup.finalTorque >= makeup.minTorque &&
              makeup.finalMakeup.finalTorque <= makeup.maxTorque
                ? "var(--parker-green)"
                : "var(--parker-red)",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Final:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>{makeup?.finalMakeup?.finalTorque ?? "-"}</Typography>
          </Box>
        </Box>

        {/* Shoulder */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Shoulder:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>
              {makeup?.finalMakeup?.shoulderTorque ?? "-"}
            </Typography>
          </Box>
        </Box>

        {/* Delta */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Delta:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>{makeup?.finalMakeup?.deltaTorque ?? "-"}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Turns Box */}
      <Box
        sx={{
          backgroundColor: "var(--parker-cyan)",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
          fontWeight: "bold",
          marginBottom: "1em",
          width: "calc(100% - 1em)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            variant='h6'
            sx={{ color: "white", textAlign: "center", width: "100%" }}
          >
            Turns
          </Typography>
        </Box>

        {/* Final */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Final:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>{makeup?.finalMakeup?.finalTurns ?? "-"}</Typography>
          </Box>
        </Box>

        {/* Shoulder */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Shoulder:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>{makeup?.finalMakeup?.shoulderTurns ?? "-"}</Typography>
          </Box>
        </Box>

        {/* Delta */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Delta:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>{makeup?.finalMakeup?.deltaTurns ?? "-"}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Makeup Time Box */}
      <Box
        sx={{
          backgroundColor: "var(--parker-cyan)",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
          fontWeight: "bold",
          marginBottom: "1em",
          width: "calc(100% - 1em)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            variant='h6'
            sx={{ color: "white", textAlign: "center", width: "100%" }}
          >
            Final Time
          </Typography>
        </Box>

        {/* Duration */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography>
              {makeup?.finalMakeup?.finalTime != null
                ? `${makeup.finalMakeup.finalTime} sec`
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* RPM Box */}
      <Box
        sx={{
          backgroundColor: "var(--parker-cyan)",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
          fontWeight: "bold",
          marginBottom: "1em",
          width: "calc(100% - 1em)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            variant='h6'
            sx={{ color: "white", textAlign: "center", width: "100%" }}
          >
            RPM
          </Typography>
        </Box>

        {/* Final */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "0.25em 1em",
            justifyContent: "space-evenly",
            marginBottom: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0 0 0 2em",
              width: "calc(50% - 2em)",
              justifyContent: "left",
            }}
          >
            <Typography>Max:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "0 2em 0 0",
              width: "calc(50% - 2em)",
              justifyContent: "right",
            }}
          >
            <Typography>{makeup?.finalMakeup?.maxRPM ?? "-"}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Decision Box */}
      {makeup?.type === MakeupType.Makeup && (
        <Box
          sx={{
            backgroundColor:
              makeup?.finalMakeup != null
                ? makeup?.finalMakeup?.isAccepted
                  ? "var(--parker-green-accent-1)"
                  : "var(--parker-red)"
                : "var(--parker-cyan)",
            borderRadius: "0.75em",
            padding: "0.5em",
            color: "var(--parker-cyan)",
            fontWeight: "bold",
            width: "calc(100% - 1em)",
          }}
        >
          {/* Title */}
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography
              variant='h6'
              sx={{ color: "white", textAlign: "center", width: "100%" }}
            >
              Decision
            </Typography>
          </Box>

          {/* Text Box */}
          <Box
            sx={{
              backgroundColor: "white",
              width: "calc(100% - 1em)",
              borderRadius: "0.75em",
              padding: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            {/* Decision Status */}
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                color: "var(--parker-cyan)",
              }}
            >
              {makeup?.finalMakeup != null
                ? makeup?.finalMakeup?.isAccepted
                  ? "Accepted"
                  : "Rejected"
                : "-"}
            </Typography>

            {/* Decision Text */}
            {makeup?.finalMakeup && (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "grey",
                }}
              >
                {makeup?.finalMakeup?.decision}
              </Typography>
            )}
          </Box>

          {/* View Details Button */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {makeup?.finalMakeup?.decision && (
              <Button onClick={() => handleSetupModalAndOpen()}>
                View Details
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
