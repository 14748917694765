export const ToGUIDSignature = (str?: string) => {
    if (!str) {
        return "-";
    }

    if (str.length <= 8) {
        return str;
    }

    return str.substring(str.length - 8);
};