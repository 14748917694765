import { Box, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import ListIcon from "@mui/icons-material/List";
import TuneIcon from "@mui/icons-material/Tune";
import EditNoteIcon from "@mui/icons-material/EditNote";
import JobIcon from "../Assets/JobsIcon.png";

export const PaneMenu = ({
  job,
  setSelectedPane,
  selectedPane,
}: {
  job: Job | null;
  setSelectedPane: React.Dispatch<React.SetStateAction<number>>;
  selectedPane: number;
}) => {
  return (
    <Box
      sx={{
        borderRadius: "0.75em",
        color: "white",
        fontWeight: "bold",
        width: "calc(100% - 1em)",
        padding: "0 0.5em",
      }}
    >
      {/* Jobs */}
      <Box
        sx={{
          display: "flex",
          textDecoration: selectedPane === 0 ? "underline" : "none",
          cursor: "pointer",
          marginBottom: "0.5em",
        }}
        onClick={() => {
          setSelectedPane(0);
        }}
      >
        <Box
          sx={{
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            display: "inline-block",
          }}
        >
          <img width='100%' height='100%' src={JobIcon} alt='job' />
        </Box>
        <Typography sx={{ margin: "0 0 0 0.5em" }}>Jobs</Typography>
      </Box>

      {/* Job Details */}
      <Box
        sx={{
          display: "flex",
          textDecoration: selectedPane === 1 ? "underline" : "none",
          cursor: "pointer",
          marginBottom: "0.5em",
        }}
        onClick={() => {
          setSelectedPane(1);
        }}
      >
        <ListIcon />
        <Typography sx={{ margin: "0 0 0 0.5em" }}>Job Details</Typography>
      </Box>

      {/* System Calibration */}
      <Box
        sx={{
          display: "flex",
          textDecoration: selectedPane === 2 ? "underline" : "none",
          cursor: "pointer",
          marginBottom: "0.5em",
        }}
        onClick={() => {
          setSelectedPane(2);
        }}
      >
        <TuneIcon />
        <Typography sx={{ margin: "0 0 0 0.5em" }}>
          System Calibration
        </Typography>
      </Box>

      {/* Job Notes */}
      <Box
        sx={{
          display: "flex",
          textDecoration: selectedPane === 3 ? "underline" : "none",
          cursor: "pointer",
        }}
        onClick={() => {
          setSelectedPane(3);
        }}
      >
        <EditNoteIcon />
        <Typography sx={{ margin: "0 0 0 0.5em" }}>Job Notes</Typography>
      </Box>
    </Box>
  );
};
