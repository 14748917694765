import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import { Box, IconButton, Switch, Typography } from "@mui/material";
import { Button } from "./Button";
import { Makeup } from "../Interfaces/Makeup";
import { DisplayMode } from "../Interfaces/DisplayMode";

export const ControlsFooter = ({
  setIsDisplayLast10,
  setSelectedLast10Makeup,
  setIsLiveDataMode,
  setDisplayMode,
  handleMoveToNextMakeup,
  handleMoveToPrevMakeup,
  makeupIndex,
  makeupsLength,
  isDisplayLast10,
  isLiveDataMode,
  liveLatency,
  displayMode,
}: {
  setIsDisplayLast10: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedLast10Makeup: React.Dispatch<
    React.SetStateAction<Makeup[] | null>
  >;
  setIsLiveDataMode: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayMode: React.Dispatch<React.SetStateAction<DisplayMode>>;
  handleMoveToNextMakeup: () => void;
  handleMoveToPrevMakeup: () => void;
  makeupIndex: number;
  makeupsLength: number;
  isDisplayLast10: boolean;
  isLiveDataMode: boolean;
  liveLatency: number;
  displayMode: DisplayMode;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        margin: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          height: "70px",
          width: "100%",
          background: "var(--parker-cyan)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            margin: "0 1em",
            color: "white",
          }}
        >
          {/* Left Group */}
          <Box
            sx={{
              display: "flex",
              width: "50%",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {/* Inner Left Group */}
            <Box
              sx={{
                display: "flex",
                width: "34%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            ></Box>

            {/* Inner Middle Group */}
            <Box
              sx={{
                display: "flex",
                width: "33%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {!isLiveDataMode ? (
                <>
                  {/* Left Arrow */}
                  <IconButton
                    disabled={makeupIndex === 0 || isLiveDataMode}
                    sx={{ color: "white" }}
                    onClick={() => {
                      setSelectedLast10Makeup(null);
                      handleMoveToPrevMakeup();
                    }}
                  >
                    <ArrowBackIcon fontSize='large' />
                  </IconButton>

                  {/* Title */}
                  <Typography
                    sx={{ margin: "0 1em", userSelect: "none" }}
                    variant='h5'
                  >
                    Recall
                  </Typography>

                  {/* Right Arrow */}
                  <IconButton
                    disabled={
                      makeupIndex === makeupsLength - 1 ||
                      makeupsLength === 0 ||
                      isLiveDataMode
                    }
                    sx={{ color: "white" }}
                    onClick={() => {
                      setSelectedLast10Makeup(null);
                      handleMoveToNextMakeup();
                    }}
                  >
                    <ArrowForwardIcon fontSize='large' />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
            </Box>

            {/* Inner Right Group */}
            <Box
              sx={{
                display: "flex",
                width: "33%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            ></Box>
          </Box>

          {/* Right Group */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "50%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {/* Inner Left Group */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "66%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {/* Live View Button */}
              <Box
                sx={{
                  display: "flex",
                  width: "calc(25% - 1em)",
                  margin: "0 0.5em",
                  justifyContent: "center",
                }}
              >
                <Button onClick={() => setIsLiveDataMode(!isLiveDataMode)}>
                  {isLiveDataMode ? "Recall Mode" : "Live View"}
                </Button>
              </Box>

              {/* Overlay Last 10 Button */}
              <Box
                sx={{
                  display: "flex",
                  width: "calc(25% - 1em)",
                  margin: "0 0.5em",
                  justifyContent: "center",
                }}
              >
                {!isLiveDataMode && (
                  <Button
                    onClick={() => {
                      setSelectedLast10Makeup(null);
                      setIsDisplayLast10(!isDisplayLast10);
                    }}
                    disabled={isLiveDataMode}
                  >
                    Overlay Last 10
                  </Button>
                )}
              </Box>

              {/* Display Mode Button */}
              <Box
                sx={{
                  display: "flex",
                  width: "calc(50% - 1em)",
                  margin: "0 0.5em",
                  justifyContent: "center",
                }}
              >
                {/* TODO: Currently this has been implemented with a switch that toggles between a line and area chart for Torque/Turns, eventually this
                          will need to change to a dropdown (technically drop-"up") selection to support different Axis types and different chart types */}
                {!isLiveDataMode && (
                  <>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textWrap: "nowrap",
                      }}
                      variant='h5'
                    >
                      Fill Graph
                    </Typography>
                    <Switch
                      sx={{
                        opacity: isLiveDataMode || isDisplayLast10 ? "0.6" : "",
                        "& .MuiSwitch-thumb": {
                          color: "var(--parker-blue)",
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "white !important",
                          opacity: "0.9 !important",
                        },
                      }}
                      checked={
                        (displayMode === DisplayMode.TorqueTurnAreaChart
                          ? false
                          : true) || isDisplayLast10
                      }
                      onChange={() => {
                        setDisplayMode(
                          displayMode === DisplayMode.TorqueTurnAreaChart
                            ? DisplayMode.TorqueTurnLineChart
                            : DisplayMode.TorqueTurnAreaChart
                        );
                      }}
                      disabled={isLiveDataMode || isDisplayLast10}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textWrap: "nowrap",
                      }}
                      variant='h5'
                    >
                      Line Graph
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            {/* Inner Right Group */}
            <Box
              sx={{
                display: "flex",
                width: "calc(34% - 1em)",
                margin: "0 1em 0 0",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {/* Live Data Latency */}
              <Box
                sx={{
                  width: "calc(100% - 1em)",
                  margin: "0 1em",
                  wordWrap: "nowrap",
                }}
              >
                {isLiveDataMode && (
                  <Typography>{`Latency: ${liveLatency}ms`}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
