import { Button as MUIButton } from "@mui/material";
import { ReactNode } from "react";

export const Button = ({
  size,
  onClick,
  disabled,
  children,
  variant = "light",
}: {
  size?: "small" | "medium" | "large" | undefined;
  onClick?: () => any;
  disabled?: boolean | undefined;
  children?: ReactNode;
  variant?: "light" | "dark";
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return variant === "light" ? (
    <MUIButton
      onClick={onClick}
      variant='contained'
      disabled={disabled ?? false}
      size={size != null ? size : "small"}
      sx={{
        backgroundColor: "white",
        color: disabled ? "white" : "var(--parker-cyan)",
        fontWeight: "400",
        textTransform: "none",
        borderRadius: "1em",
        border: "1px solid var(--parker-blue)",
        "&:hover": {
          backgroundColor: "var(--parker-cyan)",
          color: "white",
        },
      }}
    >
      {children}
    </MUIButton>
  ) : (
    <MUIButton
      onClick={onClick}
      variant='contained'
      size={size != null ? size : "small"}
      sx={{
        backgroundColor: "var(--parker-cyan)",
        color: "white",
        fontWeight: "400",
        textTransform: "none",
        borderRadius: "1em",
        border: "1px solid var(--parker-blue)",
        "&:hover": {
          backgroundColor: "white",
          color: "var(--parker-cyan)",
        },
      }}
    >
      {children}
    </MUIButton>
  );
};
