export interface Base {
    id: string;
    dateCreated: Date;
    dateUpdated?: Date;
    dateDeleted?: Date;
    isDeleted: boolean;
};

export enum CrewMemberRole {
    Supervisor,
    Inspector,
    Operator,
}

export enum MakeupType {
    Makeup,
    Breakout,
}

export enum DeviceType {
    PowerTong,
    CRT,
}