import { useContext, useState, createContext, ReactNode } from "react";

export interface ModalContextType {
  modalIsShown: boolean;
  openModal: () => void;
  closeModal: () => void;
  setTitle: (title: string) => void;
  setDecision: (decision: string) => void;
  setComment: (comment: string) => void;
  setModalData: (title: string, decision: string, comment: string) => void;
  title: string;
  decision: string;
  comment: string;
}

const ModalContext = createContext<ModalContextType>({
  modalIsShown: false,
  openModal: () => {},
  closeModal: () => {},
  setTitle: () => {},
  setDecision: () => {},
  setComment: () => {},
  setModalData: () => {},
  title: "Modal Title",
  decision: "Add A Decision Here",
  comment: "Add A Comment Here",
});

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [modalIsShown, setModalIsShown] = useState<boolean>(false);
  const [title, setTitleState] = useState<string>("");
  const [decision, setDecisionState] = useState<string>("");
  const [comment, setCommentState] = useState<string>("");

  const openModal = () => setModalIsShown(true);
  const closeModal = () => setModalIsShown(false);
  const setTitle = (title: string) => setTitleState(title);
  const setDecision = (decision: string) => setDecisionState(decision);
  const setComment = (comment: string) => setCommentState(comment);
  const setModalData = (title: string, decision: string, comment: string) => {
    setTitleState(title);
    setDecisionState(decision);
    setCommentState(comment);
  };

  return (
    <ModalContext.Provider
      value={{
        modalIsShown,
        openModal,
        closeModal,
        title,
        decision,
        comment,
        setTitle,
        setDecision,
        setComment,
        setModalData,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined)
    throw new Error("useModalContext must be setup in the app router.");

  return context;
};
