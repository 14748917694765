import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  LineChart,
  Line,
  LabelProps,
} from "recharts";
import { Makeup } from "../../Interfaces/Makeup";
import { Box, Typography } from "@mui/material";

export const OverlayLastTenGraph = ({
  shoulderOffset,
  last10Makeups,
  last10ShoulderOffets,
  xTicks,
  yTicks,
  xMinValue,
  xMaxValue,
  yDomainMin,
  yDomainMax,
  maxTorque,
  optimumTorque,
  minTorque,
  maxShoulder,
  minShoulder,
  refTorque,
  ShowRefLineValues,
  refLineLabelPosition,
  refLineLabelXOffset,
  refLineLabelYOffset,
  refLineValuePosition,
  refLineValueXOffset,
  refLineValueYOffset,
  xAxisName,
  yAxisName,
}: {
  shoulderOffset: string;
  last10Makeups: Makeup[] | null;
  last10ShoulderOffets: string[];
  xTicks: number[];
  yTicks: number[];
  xMinValue: number;
  xMaxValue: number;
  yDomainMin: number;
  yDomainMax: number;
  maxTorque: number | undefined;
  optimumTorque: number | undefined;
  minTorque: number | undefined;
  maxShoulder: number | undefined;
  minShoulder: number | undefined;
  refTorque: number | undefined;
  ShowRefLineValues: boolean;
  refLineLabelPosition: LabelProps["position"];
  refLineLabelXOffset: number;
  refLineLabelYOffset: number;
  refLineValuePosition: LabelProps["position"];
  refLineValueXOffset: number;
  refLineValueYOffset: number;
  xAxisName: string;
  yAxisName: string;
}) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 20,
          right: 48,
          left: 40,
          bottom: 20,
        }}
      >
        {/* Grid Dashes */}
        <CartesianGrid strokeDasharray='5 5' />

        {/* X =-Axis Settings */}
        <XAxis
          type='number'
          dataKey={"turns"}
          scale='linear'
          ticks={xTicks}
          domain={[xMinValue, xMaxValue]}
        >
          <Label
            value={xAxisName}
            offset={-10}
            position='insideBottom'
            style={{
              textAnchor: "middle",
              fontSize: "130%",
            }}
          />
        </XAxis>

        {/* Y-Axis Settings */}
        <YAxis
          type='number'
          dataKey={"torque"}
          scale='linear'
          ticks={yTicks}
          domain={[yDomainMin, yDomainMax]}
          tickFormatter={(value) =>
            `${parseInt(
              ((value / (optimumTorque ?? 100)) * 100).toString(),
              10
            )}%`
          }
        >
          <Label
            value={`${yAxisName} (ft.lbs)`}
            angle={-90}
            offset={-24}
            position='insideLeft'
            style={{
              textAnchor: "middle",
              fontSize: "130%",
            }}
          />
        </YAxis>

        {/* Tooltip */}
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={({ active, payload, label }) => {
            if (active && payload) {
              return (
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "0.75em",
                    border: "1px solid #ccc",
                    padding: "0.5em",
                  }}
                >
                  {payload.map(
                    (data, index) =>
                      last10Makeups &&
                      last10Makeups[index].maxTurns >= label && (
                        <Typography
                          key={`last-10-tooltip-${index}`}
                        >{`Graph No ${last10Makeups[index].graphNumber} : ${
                          last10Makeups[index].makeupTelemetry.find(
                            (x) => x.turns === label
                          )?.torque
                        }`}</Typography>
                      )
                  )}
                  <Typography>{`${xAxisName} : ${label}`}</Typography>
                </Box>
              );
            }
          }}
        />

        {/* Gradient Defintion for Green-to-Red Color Change */}
        {last10Makeups &&
          last10Makeups.map((makeup, index) => (
            <defs key={`last-10-gradient-${index}`}>
              <linearGradient
                id={`areaFill-${makeup.makeupNumber}-${makeup.graphNumber}`}
                x1='0%'
                y1='0'
                x2='100%'
                y2='0'
              >
                <stop
                  offset={
                    last10ShoulderOffets[index].length > 0
                      ? last10ShoulderOffets[index]
                      : "0%"
                  }
                  stopColor='var(--parker-green-accent-2)'
                  stopOpacity={1}
                />
                <stop
                  offset={
                    last10ShoulderOffets[index].length > 0
                      ? last10ShoulderOffets[index]
                      : "0%"
                  }
                  stopColor='var(--parker-red)'
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
          ))}

        {/* Line Settings */}
        {last10Makeups &&
          last10Makeups.map((makeup) => (
            <Line
              key={`last-10-graph-no-${makeup.graphNumber}`}
              type='monotone'
              dataKey='torque'
              data={makeup.makeupTelemetry}
              name={`Graph No ${makeup.graphNumber}`}
              stroke={
                shoulderOffset !== ""
                  ? `url(#areaFill-${makeup.makeupNumber}-${makeup.graphNumber})`
                  : "var(--parker-blue-accent-1)"
              }
              dot={false}
            />
          ))}

        {/* Value Lines */}
        <ReferenceLine y={maxTorque} stroke='red'>
          <Label
            value='Max'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={maxTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='firebrick'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={optimumTorque} stroke='green'>
          <Label
            value='Opt'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={optimumTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='forestgreen'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={minTorque} stroke='yellow'>
          <Label
            value='Min'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={minTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='darkkhaki'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={maxShoulder} stroke='blue' strokeDasharray='10 5'>
          <Label
            value='MxS'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={maxShoulder}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='mediumblue'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={minShoulder} stroke='blue' strokeDasharray='10 5'>
          <Label
            value='MnS'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={minShoulder}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='mediumblue'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={refTorque} stroke='blue'>
          <Label
            value='Ref'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={refTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='mediumblue'
            />
          )}
        </ReferenceLine>
      </LineChart>
    </ResponsiveContainer>
  );
};
