export interface IotMessage {
    MessageId: string;
    Type: RecordType;
    Telemetry: string;
}

export interface JobNoteMessage {
    JobId: string;
    JobNotes: string;
}

export enum RecordType {
    Job,
    JobNote,
    CrewMember,
    SystemCalibration,
    Makeup,
    MakeupTelemetry,
    FinalMakeup,
}