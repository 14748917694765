import { Box, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import { ToGUIDSignature } from "../Utilities/GUIDUtility";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import { CustomTooltip } from "./CustomTooltip";

export const JobCard = ({
  job,
  isSelected,
}: {
  job: Job;
  isSelected: boolean;
}) => {
  const nav = useNavigate();

  const resolveInitials = () => {
    let initials = "";

    let splitName = job.customerName?.split(" ");

    if (splitName && splitName.length > 0) {
      initials = splitName
        ?.slice(0, 2)
        .map((name) => name[0])
        .join("");
    }

    return initials;
  };

  return (
    <Box
      onClick={() => nav(`/${job.id}`)}
      className={isSelected ? "job-card-selected" : "job-card"}
      sx={{
        backgroundColor: isSelected ? "#C9DCEA" : "white",
        cursor: "pointer",
      }}
    >
      {/* Main Container */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignContent: "center",
          alignItems: "center",
          margin: isSelected ? "0" : "0 0 0.5em 0",
        }}
      >
        {/* Customer Initials */}
        <Box
          sx={{
            width: "18%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ margin: "0 0 0 0.5em" }}>
            <Typography variant='h5' sx={{ textAlign: "center" }}>
              {resolveInitials()}
            </Typography>
          </Box>
        </Box>

        {/* Job Details */}
        <Box
          sx={{
            width: "82%",
            height: "calc(100% - 1em)",
            margin: "0.5em 0.5em 0 0.5em",
          }}
        >
          {/* Job Number */}
          <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                marginRight: "0.25em",
                userSelect: "none",
                whiteSpace: "nowrap",
              }}
            >
              Job No:
            </Typography>
            <CustomTooltip title={job.id}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  userSelect: "none",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {ToGUIDSignature(job.id)}
              </Typography>
            </CustomTooltip>
          </Box>

          {/* Location */}
          <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
            <Typography
              sx={{
                fontSize: "12px",
                userSelect: "none",
                marginRight: "0.25em",
                flexWrap: "nowrap",
              }}
            >
              Location:
            </Typography>
            <CustomTooltip title={job.location ?? "-"}>
              <Typography
                sx={{
                  fontSize: "12px",
                  userSelect: "none",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {job.location ?? "-"}
              </Typography>
            </CustomTooltip>
          </Box>

          {/* Well Number */}
          <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
            <Typography
              sx={{
                fontSize: "12px",
                userSelect: "none",
                marginRight: "0.25em",
                whiteSpace: "nowrap",
              }}
            >
              Well No:
            </Typography>
            <CustomTooltip title={job.wellNumber ?? "-"}>
              <Typography
                sx={{
                  fontSize: "12px",
                  userSelect: "none",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {job.wellNumber ?? "-"}
              </Typography>
            </CustomTooltip>
          </Box>

          {/* Customer */}
          <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
            <Typography
              sx={{
                fontSize: "12px",
                userSelect: "none",
                marginRight: "0.25em",
              }}
            >
              Customer:
            </Typography>
            <CustomTooltip title={job.customerName ?? "-"}>
              <Typography
                sx={{
                  fontSize: "12px",
                  userSelect: "none",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {job.customerName ?? "-"}
              </Typography>
            </CustomTooltip>
          </Box>

          {/* Date */}
          <Typography sx={{ fontSize: "12px", userSelect: "none" }}>
            Date:{" "}
            {job?.startDate != null
              ? new Date(job.startDate)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ")
              : "-"}
          </Typography>
        </Box>
      </Box>

      {/* Active Connections */}
      {isSelected && (
        <Box
          sx={{
            margin: "0.2em 0 0.5em 1.4em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <CircleIcon style={{ fontSize: 10, color: "#23b40b" }} />
            <Typography sx={{ margin: "0 0 0 1em", fontSize: "11px" }}>
              Accepted Connections:{" "}
              {(job?.makeups &&
                job.makeups.filter((x) => x.finalMakeup?.isAccepted === true)
                  .length) ??
                "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <CircleIcon style={{ fontSize: 10, color: "#dd0c19" }} />
            <Typography sx={{ margin: "0 0 0 1em", fontSize: "11px" }}>
              Rejected Connections:{" "}
              {(job?.makeups &&
                job.makeups.filter((x) => x.finalMakeup?.isAccepted === false)
                  .length) ??
                "-"}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
