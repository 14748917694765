import { Http } from "../Utilities/Http";
import { Response } from "../Interfaces/Response";
import { MakeupTelemetry } from '../Interfaces/MakeupTelemetry';

const getMatchingTelemetry = async (jobId: string, makeupNumber: number, graphNumber: number): Promise<Response<MakeupTelemetry[]>> => {
	const res = await Http.GET(`/MakeupTelemetry/${jobId}/${makeupNumber}/${graphNumber}`);
	return res.data;
};

export const MakeupTelemetryService = {
	getMatchingTelemetry,
};