import { Box, Modal, Typography } from "@mui/material";
import { useModalContext } from "../Contexts/ModalContext";
import { Button } from "./Button";
import Draggable from "react-draggable";
import React from "react";

export const DecisionModal = () => {
  const modalContext = useModalContext();

  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  const [isLocked, setIsLocked] = React.useState(false);

  React.useEffect(() => {
    setPosition({ x: window.screen.width / 2 - 350, y: 10 });
  }, [modalContext.modalIsShown]);

  const handleDrag = (data: any) => {
    if (!isLocked) {
      setPosition({
        x: data.lastX + data.deltaX,
        y: data.lastY + data.deltaY,
      });
    } else {
      setIsLocked(false);
    }
  };

  return (
    <Box
      sx={{
        top: "0",
        left: "0",
        margin: "0",
        padding: "0",
        display: "block",
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: "100",
        pointerEvents: "none",
      }}
    >
      <Draggable
        defaultPosition={{ x: 0, y: 0 }}
        onDrag={(event, data) => {
          handleDrag(data);
        }}
        onStop={(event, data) => {
          handleDrag(data);
        }}
        position={position}
        bounds='parent'
      >
        <Modal
          hideBackdrop={true}
          sx={{
            width: "700px",
            height: "255px",
          }}
          open={modalContext.modalIsShown}
        >
          <Box
            sx={{
              width: "600px",
              height: "200px",
              backgroundColor: "white",
              border: "2px solid var(--parker-cyan)",
              borderRadius: "15px",
              padding: "1.5em 3em",
            }}
          >
            <Box sx={{ height: "20%" }}>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "var(--parker-cyan)",
                  fontWeight: "bold",
                }}
                variant='h5'
              >
                {modalContext.title}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "110px",
                  overflowY: "auto",
                }}
              >
                <Typography
                  sx={{
                    display: "block",
                    color: "var(--parker-cyan)",
                    width: "100%",
                    height: "20%",
                    textAlign: "left",
                  }}
                  onScroll={(event) => {
                    setIsLocked(true);
                  }}
                >
                  {modalContext.decision}
                </Typography>
                <Typography
                  sx={{
                    display: "block",
                    color: "var(--parker-cyan)",
                    width: "100%",
                    height: "calc(80% - 10px)",
                    margin: "10px 0 0 0",
                    textAlign: "left",
                  }}
                  onScroll={(event) => {
                    setIsLocked(true);
                  }}
                >
                  {modalContext.comment}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.5em",
                }}
              >
                <Button
                  variant='dark'
                  size={"medium"}
                  onClick={modalContext.closeModal}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Draggable>
    </Box>
  );
};
