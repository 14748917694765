import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { MainLayout } from "./Components/MainLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import { ModalContextProvider } from "./Contexts/ModalContext";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Auth } from "./Auth";
import { WebPubSubContextProvider } from "./Contexts/WebPubSubContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AppRouter = () => {
  return (
    <ModalContextProvider>
      <QueryClientProvider client={queryClient}>
        <AuthenticatedTemplate>
          <WebPubSubContextProvider>
            <HashRouter>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  <Route path="/:id" element={<Home />} />
                  <Route path="/" element={<Home />} />
                </Route>
              </Routes>
            </HashRouter>
          </WebPubSubContextProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <HashRouter>
            <Routes>
              <Route path="/" element={<Auth />} />
              <Route path="/auth" element={<Auth />} />
            </Routes>
          </HashRouter>
        </UnauthenticatedTemplate>
      </QueryClientProvider>
    </ModalContextProvider>
  );
};
