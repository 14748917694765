import { Http } from "../Utilities/Http";
import { Makeup } from "../Interfaces/Makeup";
import { Response } from "../Interfaces/Response";

const getMakeupById = async (makeupId: string): Promise<Response<Makeup>> => {
	const res = await Http.GET(`/Makeup/${makeupId}`);
	return res.data;
}

const getMatchingMakeup = async (jobId: string, makeupNumber: number, graphNumber: number): Promise<Response<Makeup>> => {
	const res = await Http.GET(`/Makeup/${jobId}/${makeupNumber}/${graphNumber}`);
	return res.data;
}

const getLast10MakeupsById = async (jobId: string, currentMakeupId: string): Promise<Response<Makeup[]>> => {
	const res = await Http.GET(`/Makeup/LastTen/${jobId}/${currentMakeupId}`)
	return res.data;
}

export const MakeupService = {
	getMakeupById,
	getLast10MakeupsById,
	getMatchingMakeup
}
