import { Http } from "../Utilities/Http";
import { Job } from "../Interfaces/Job";
import { Response } from "../Interfaces/Response";

const getAllJobs = async (pageNumber?: number, pageSize = 25): Promise<Response<Job[]>> => {
    let res;

    if (!pageNumber) {
        res = await Http.GET(`/Jobs`);
    }
    else {
        res = await Http.GET(`/Jobs?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    return res.data;
};

const getAllJobsLight = async (pageNumber?: number, pageSize = 25): Promise<Response<Job[]>> => {
    let res;

    if (!pageNumber) {
        res = await Http.GET(`/Jobs/light`);
    }
    else {
        res = await Http.GET(`/Jobs/light?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    return res.data;
};

const getJobDataById = async (id: string): Promise<Response<Job>> => {
    const res = await Http.GET(`/Jobs/${id}`);
    return res.data;
};

const getJobDataByIdLight = async (id: string): Promise<Response<Job>> => {
    const res = await Http.GET(`/Jobs/light/${id}`);
    return res.data;
};

const getMatchingJobs = async (searchString: string, pageNumber?: number, pageSize = 25): Promise<Response<Job[]>> => {
    let res;

    if (!pageNumber) {
        res = await Http.GET(`/Jobs/search/${searchString}`);
    }
    else {
        res = await Http.GET(`/Jobs/search/${searchString}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    return res.data;
};

const getMatchingJobsLight = async (searchString: string, pageNumber?: number, pageSize = 25): Promise<Response<Job[]>> => {
    let res;

    if (!pageNumber) {
        res = await Http.GET(`/Jobs/light/search/${searchString}`);
    }
    else {
        res = await Http.GET(`/Jobs/light/search/${searchString}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    return res.data;
};

export const JobService = {
    getAllJobs,
    getAllJobsLight,
    getJobDataById,
    getJobDataByIdLight,
    getMatchingJobs,
    getMatchingJobsLight,
};