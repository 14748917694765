import { WebPubSubClient } from "@azure/web-pubsub-client";
import {
  useContext,
  useState,
  createContext,
  ReactNode,
  useEffect,
} from "react";
import { IotMessage } from "../Interfaces/IotMessage";
import { PubSubService } from "../Services/PubSubService";

export interface WebPubSubContextType {
  webPubSubClient: WebPubSubClient | null;
  realtimeMessages: [IotMessage, number][];
  joinGroup: (id: string) => any;
  leaveGroup: (id: string) => any;
}

const WebPubSubContext = createContext<WebPubSubContextType>({
  webPubSubClient: null,
  realtimeMessages: [],
  joinGroup: () => {},
  leaveGroup: () => {},
});

export const WebPubSubContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [webPubSubClient, setWebPubSubClient] =
    useState<WebPubSubClient | null>(null);

  const [realtimeMessages, setRealtimeMessages] = useState<
    [IotMessage, number][]
  >([]);

  const joinGroup = (id: string) => {
    try {
      if (webPubSubClient) {
        webPubSubClient.joinGroup(id).then(() => {
          webPubSubClient.on("group-message", handleGroupMessage);
        });
      }
    } catch (e) {
      console.error(`An error occurred when joining group ${id}`, e);
    }
  };

  const leaveGroup = (id: string) => {
    try {
      if (webPubSubClient) {
        webPubSubClient.leaveGroup(id).then(() => {
          webPubSubClient.off("group-message", handleGroupMessage);
        });
      }
    } catch (e) {
      console.error(`An error occurred when leaving group ${id}`, e);
    }
  };

  const handleGroupMessage = (e: any) => {
    const message: IotMessage = JSON.parse(e.message.data.toString());
    setRealtimeMessages((prev) => [...prev, [message, Date.now()]]);
  };

  useEffect(() => {
    PubSubService.getPubSubToken()
      .then(async (token) => {
        const client = new WebPubSubClient(token);
        await client.start();

        setWebPubSubClient(client);
      })
      .catch((e) => {
        console.error("An error occurred when getting the PubSub token", e);
      });
    return () => {
      if (webPubSubClient) {
        webPubSubClient.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WebPubSubContext.Provider
      value={{
        webPubSubClient,
        realtimeMessages,
        joinGroup,
        leaveGroup,
      }}
    >
      {children}
    </WebPubSubContext.Provider>
  );
};

export const useWebPubSubContext = () => {
  const context = useContext(WebPubSubContext);

  if (context === undefined)
    throw new Error("useWebPubSubContext must be setup in the app router.");

  return context;
};
