import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { msalConfig } from "./MsalUtility";
import { jwtDecode } from "jwt-decode";

const getBearerToken = async (): Promise<string | null> => {
    await handleValidateAndRefreshCurrentToken();
    const BEARER = localStorage.getItem('MSAL_ACCESS_TOKEN');
    return BEARER ? `Bearer ${BEARER}` : null;
};

const handleValidateAndRefreshCurrentToken = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    try {
        const token = localStorage.getItem("MSAL_ACCESS_TOKEN");
        let expired = false;

        if (token) {
            const decodedJWT = jwtDecode(token);
            if (decodedJWT) {
                const exp = decodedJWT?.exp;
                const now = new Date().getTime() / 1000;
                expired = exp ? now > exp : true;
            }
        }

        if (expired || !token) {
            await msalInstance.initialize();
            const accounts = msalInstance.getAllAccounts();

            const newToken = await msalInstance.acquireTokenSilent({
                scopes: [
                    `api://${process.env.REACT_APP_API_REGISTRATION_CLIENT_ID}/TorqTekApiAccess`,
                ],
                account: accounts[0],
            });
            localStorage.setItem("MSAL_ACCESS_TOKEN", newToken.accessToken);
        }
    } catch (e) {
        localStorage.removeItem("MSAL_ACCESS_TOKEN");
        console.error(e);
        window.location.reload();
    }
};

const getRequestHeaders = async () => {
    return {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: await getBearerToken()
    };
};

const getBaseUrl = (): string => {
    const defaultUrl = "https://localhost:5001/api";
    return process.env.REACT_APP_API_URL ?? defaultUrl;
};

const GET = async (url: string) => {
    const res = await axios.get(`${getBaseUrl()}${url}`, {
        headers: await getRequestHeaders()
    });
    return res;
};

const POST = async (url: string, data: any) => {
    const res = await axios.post(`${getBaseUrl()}${url}`, data, {
        headers: await getRequestHeaders()
    });
    return res;
};

const PUT = async (url: string, data: any) => {
    const res = await axios.put(`${getBaseUrl()}${url}`, data, {
        headers: await getRequestHeaders()
    });
    return res;
};

export const Http = {
    GET,
    POST,
    PUT
};