import { Box, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import { ToGUIDSignature } from "../Utilities/GUIDUtility";
import { CustomTooltip } from "./CustomTooltip";

export const JobNotes = ({ job }: { job: Job | null }) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* Job Notes */}
      <Box
        sx={{
          height: "calc(100% - 1em)",
          backgroundColor: "white",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
        }}
      >
        {/* Title */}
        <Box
          sx={{
            height: "5%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              width: "100%",
              fontWeight: "bold",
            }}
            variant="h6"
          >
            Job Notes
          </Typography>
        </Box>

        {/* Job Id */}
        <Box
          sx={{
            display: "flex",
            height: "5%",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              marginRight: "0.5em",
              fontWeight: "bold",
            }}
          >
            Job Id:
          </Typography>

          {/* Job Id Tooltip */}
          <CustomTooltip title={job?.id || ""}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {ToGUIDSignature(job?.id)}
            </Typography>
          </CustomTooltip>
        </Box>

        {/* Separation Line */}
        <Box
          sx={{
            display: "flex",
            height: "calc(2% - 1em)",
            alignItems: "center",
            margin: "0.5em 0",
            borderBottom: "1px solid var(--parker-cyan-accent)",
          }}
        />

        {/* Notes Textbox Title */}
        <Box
          sx={{
            display: "flex",
            height: "5%",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              marginRight: "0.5em",
              fontWeight: "bold",
            }}
          >
            Notes
          </Typography>
        </Box>

        {/* Notes Textbox */}
        <Box
          sx={{
            height: "calc(83% - 1.5em)",
            border: "1px solid var(--parker-cyan)",
            borderRadius: "15px",
            padding: "0.5em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "0.8em",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {job?.jobNotes ?? ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
