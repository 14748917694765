import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  LabelProps,
} from "recharts";
import { MakeupTelemetry } from "../../Interfaces/MakeupTelemetry";
import { Box, Typography } from "@mui/material";
import { DisplayMode } from "../../Interfaces/DisplayMode";

// TODO: Currently the dot associated with the graph tooltip is having the gradient we're creating applied to it (so the dot itself is 2 colors),
//       need to look into how to stop this from happening, really, the dot should just be the color of whatever the graph color is at that specific point
export const PrimaryGraph = ({
  makeupTelemetry,
  shoulderOffset,
  xTicks,
  yTicks,
  xMinValue,
  xMaxValue,
  yDomainMin,
  yDomainMax,
  maxTorque,
  optimumTorque,
  minTorque,
  maxShoulder,
  minShoulder,
  refTorque,
  ShowRefLineValues,
  refLineLabelPosition,
  refLineLabelXOffset,
  refLineLabelYOffset,
  refLineValuePosition,
  refLineValueXOffset,
  refLineValueYOffset,
  xAxisName,
  yAxisName,
  displayMode,
}: {
  makeupTelemetry: MakeupTelemetry[] | undefined;
  shoulderOffset: string;
  xTicks: number[];
  yTicks: number[];
  xMinValue: number;
  xMaxValue: number;
  yDomainMin: number;
  yDomainMax: number;
  maxTorque: number | undefined;
  optimumTorque: number | undefined;
  minTorque: number | undefined;
  maxShoulder: number | undefined;
  minShoulder: number | undefined;
  refTorque: number | undefined;
  ShowRefLineValues: boolean;
  refLineLabelPosition: LabelProps["position"];
  refLineLabelXOffset: number;
  refLineLabelYOffset: number;
  refLineValuePosition: LabelProps["position"];
  refLineValueXOffset: number;
  refLineValueYOffset: number;
  xAxisName: string;
  yAxisName: string;
  displayMode: DisplayMode;
}) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <AreaChart
        width={500}
        height={300}
        data={makeupTelemetry}
        margin={{
          top: 20,
          right: 48,
          left: 40,
          bottom: 20,
        }}
      >
        {/* Grid Dashes */}
        <CartesianGrid strokeDasharray='5 5' />

        {/* X =-Axis Settings */}
        <XAxis
          type='number'
          dataKey={"turns"}
          scale='linear'
          ticks={xTicks}
          domain={[xMinValue, xMaxValue]}
        >
          <Label
            value={xAxisName}
            offset={-10}
            position='insideBottom'
            style={{
              textAnchor: "middle",
              fontSize: "130%",
            }}
          />
        </XAxis>

        {/* Y-Axis Settings */}
        <YAxis
          type='number'
          dataKey={"torque"}
          scale='linear'
          ticks={yTicks}
          domain={[yDomainMin, yDomainMax]}
          tickFormatter={(value) =>
            `${parseInt(
              ((value / (optimumTorque ?? 100)) * 100).toString(),
              10
            )}%`
          }
        >
          <Label
            value={`${yAxisName} (ft.lbs)`}
            angle={-90}
            offset={-24}
            position='insideLeft'
            style={{
              textAnchor: "middle",
              fontSize: "130%",
            }}
          />
        </YAxis>

        {/* Tooltip */}
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload) {
              return (
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "0.75em",
                    border: "1px solid #ccc",
                    padding: "0.5em",
                  }}
                >
                  <Typography>{`${yAxisName} : ${payload[0]?.value}`}</Typography>
                  <Typography>{`${xAxisName} : ${label}`}</Typography>
                </Box>
              );
            }
          }}
        />

        {/* Gradient Defintion for Green-to-Red Color Change */}
        <defs>
          <linearGradient id='areaFill' x1='0%' y1='0' x2='100%' y2='0'>
            <stop
              offset={shoulderOffset !== "" ? shoulderOffset : "0%"}
              stopColor='var(--parker-green-accent-2)'
              stopOpacity={1}
            />
            <stop
              offset={shoulderOffset !== "" ? shoulderOffset : "0%"}
              stopColor='var(--parker-red)'
              stopOpacity={1}
            />
          </linearGradient>
        </defs>

        {/* Chart Area Settings */}
        <Area
          type='monotone'
          dataKey='torque'
          stroke={
            shoulderOffset !== ""
              ? shoulderOffset === "100%"
                ? "var(--parker-green-accent-2)"
                : shoulderOffset === "0%"
                ? "var(--parker-red)"
                : "url(#areaFill)"
              : "var(--parker-blue-accent-1)"
          }
          fill={
            displayMode === DisplayMode.TorqueTurnAreaChart
              ? shoulderOffset !== ""
                ? shoulderOffset === "100%"
                  ? "var(--parker-green-accent-2)"
                  : shoulderOffset === "0%"
                  ? "var(--parker-red)"
                  : "url(#areaFill)"
                : "var(--parker-blue-accent-1)"
              : "transparent"
          }
        />

        {/* Value Lines */}
        <ReferenceLine y={maxTorque} stroke='red'>
          <Label
            value='Max'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={maxTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='firebrick'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={optimumTorque} stroke='green'>
          <Label
            value='Opt'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={optimumTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='forestgreen'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={minTorque} stroke='yellow'>
          <Label
            value='Min'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={minTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='darkkhaki'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={maxShoulder} stroke='blue' strokeDasharray='10 5'>
          <Label
            value='MxS'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={maxShoulder}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='mediumblue'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={minShoulder} stroke='blue' strokeDasharray='10 5'>
          <Label
            value='MnS'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={minShoulder}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='mediumblue'
            />
          )}
        </ReferenceLine>
        <ReferenceLine y={refTorque} stroke='blue'>
          <Label
            value='Ref'
            position={refLineLabelPosition}
            offset={refLineLabelXOffset}
            dy={refLineLabelYOffset}
          />
          {ShowRefLineValues && (
            <Label
              value={refTorque}
              position={refLineValuePosition}
              offset={refLineValueXOffset}
              dy={refLineValueYOffset}
              fontWeight='bold'
              fill='mediumblue'
            />
          )}
        </ReferenceLine>
      </AreaChart>
    </ResponsiveContainer>
  );
};
