import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import { JobDetails } from "../Components/JobDetails";
import { MakeupMetadata } from "../Components/MakeupMetadata";
import { PaneMenu } from "../Components/PaneMenu";
import { MakeupSummary } from "../Components/MakeupSummary";
import { ControlsFooter } from "../Components/ControlsFooter";
import { OngoingJobs } from "../Components/OngoingJobs";
import { SystemCalibration as SystemCalibrationComponent } from "../Components/SystemCalibration";
import { JobNotes } from "../Components/JobNotes";
import { Graph } from "../Components/Graph";
import { useGetMakeupById } from "../Hooks/useGetMakeupById";
import { Makeup } from "../Interfaces/Makeup";
import { useParams } from "react-router-dom";
import { useGetJobByIdLight } from "../Hooks/useGetJobByIdLight";
import { useGetLast10MakeupsById } from "../Hooks/useGetLast10MakesupById";
import { JobNoteMessage, RecordType } from "../Interfaces/IotMessage";
import { CrewMember } from "../Interfaces/CrewMember";
import { FinalMakeup } from "../Interfaces/FinalMakeup";
import { MakeupTelemetry } from "../Interfaces/MakeupTelemetry";
import { SystemCalibration } from "../Interfaces/SystemCalibration";
import { useGetMatchingTelemetry } from "../Hooks/useGetMatchingTelemetry";
import { useGetMatchingFinalMakeups } from "../Hooks/useGetMatchingFinalMakeups";
import { CrewMemberRole } from "../Interfaces/Base";
import { useModalContext } from "../Contexts/ModalContext";
import { DisplayMode } from "../Interfaces/DisplayMode";
import { useGetMatchingMakeup } from "../Hooks/useGetMatchingMakeup";
import { useWebPubSubContext } from "../Contexts/WebPubSubContext";
import _ from "lodash";

export const Home = () => {
  const modalContext = useModalContext();

  const { realtimeMessages, webPubSubClient, joinGroup, leaveGroup } =
    useWebPubSubContext();

  const {
    mutate: getJobById,
    data: jobById,
    isLoading: jobByIdIsLoading,
  } = useGetJobByIdLight();

  const {
    mutate: getLast10MakeupsById,
    data: last10MakeupsById,
    isLoading: last10MakeupsByIdIsLoading,
  } = useGetLast10MakeupsById();

  const {
    mutate: getMakeupById,
    data: makeupById,
    isLoading: makeupByIdIsLoading,
  } = useGetMakeupById();

  const {
    mutate: getMatchingMakeup,
    data: matchingMakeup,
    isLoading: matchingMakeupIsLoading,
  } = useGetMatchingMakeup();

  const {
    mutate: getMatchingTelemetry,
    data: matchingTelemetry,
    isLoading: matchingTelemetryIsLoading,
  } = useGetMatchingTelemetry();

  const {
    mutate: getMatchingFinalMakeups,
    data: matchingFinalMakeups,
    isLoading: matchingFinalMakeupsIsLoading,
  } = useGetMatchingFinalMakeups();

  const [jobByIdResults, setJobByIdResults] = React.useState<Job | null>(null);

  const [last10MakeupsByIdResults, setLast10MakeupsByIdResults] =
    React.useState<Makeup[] | null>(null);

  const [makeupByIdResults, setMakeupByIdResults] =
    React.useState<Makeup | null>(null);

  const [matchingMakeupResults, setMatchingMakeupResults] =
    React.useState<Makeup | null>(null);

  const [matchingTelemetryResults, setMatchingTelemetryResults] =
    React.useState<MakeupTelemetry[] | null>(null);

  const [matchingFinalMakeupsResults, setMatchingFinalMakeupsResults] =
    React.useState<FinalMakeup[] | null>(null);

  useEffect(() => {
    if (jobById && checkIfDifferent(jobById?.result, jobByIdResults)) {
      setJobByIdResults(jobById.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobById]);

  useEffect(() => {
    if (
      last10MakeupsById &&
      checkIfDifferent(last10MakeupsById?.result, last10MakeupsByIdResults)
    ) {
      setLast10MakeupsByIdResults(last10MakeupsById.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last10MakeupsById]);

  useEffect(() => {
    if (makeupById && checkIfDifferent(makeupById?.result, makeupByIdResults)) {
      setMakeupByIdResults(makeupById.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeupById]);

  useEffect(() => {
    if (
      matchingMakeup &&
      checkIfDifferent(matchingMakeup?.result, matchingMakeup)
    ) {
      setMatchingMakeupResults(matchingMakeup.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingMakeup]);

  useEffect(() => {
    if (
      matchingTelemetry &&
      checkIfDifferent(matchingTelemetry?.result, matchingTelemetryResults)
    ) {
      setMatchingTelemetryResults(matchingTelemetry.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingTelemetry]);

  useEffect(() => {
    if (
      matchingFinalMakeups &&
      checkIfDifferent(
        matchingFinalMakeups?.result,
        matchingFinalMakeupsResults
      )
    ) {
      setMatchingFinalMakeupsResults(matchingFinalMakeups.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingFinalMakeups]);

  const { id } = useParams();

  // Selected Job State - Used To Populate Page Data
  const [selectedJob, setSelectedJob] = React.useState<Job | null>(null);

  // Selected Makeup State - Used To Populate Page Data
  const [selectedMakeup, setSelectedMakeup] = React.useState<Makeup | null>(
    null
  );

  // Selected Last 10 Makeup State - Used To Populate Page Data
  const [selectedLast10Makeup, setSelectedLast10Makeup] = React.useState<
    Makeup[] | null
  >(null);

  // Used during live data
  const [dbMakeupTelemetry, setDBMakeupTelemetry] = React.useState<
    MakeupTelemetry[] | null
  >(null);

  // Used during live data
  const [dbFinalMakeup, setDBFinalMakeup] = React.useState<FinalMakeup | null>(
    null
  );

  // TODO: Currently we never reset 'liveMakeupTelemetry' or 'liveFinalMakeups' during live data/realtime mode, meaning
  //       there's a potential that if someone stays in "Realtime View" for a VERY extended period of time and they have
  //       large amounts of data coming in, there may be memory issues, need to look into how to potentially alleviate
  //       that without the possibility of losing data
  // Used during live data
  const [liveMakeupTelemetry, setLiveMakeupTelemetry] = React.useState<
    MakeupTelemetry[]
  >([]);

  // Used during live data
  const [liveFinalMakeups, setLiveFinalMakeups] = React.useState<FinalMakeup[]>(
    []
  );

  // Toggle For Slide Pane Menu
  const [isSlidePanelOpen, setIsSlidePanelOpen] = React.useState<boolean>(true);

  // State variable that handles which pane is selected
  const [selectedPane, setSelectedPane] = React.useState<number>(0);

  // Sets the index to the which makeup we're currently viewing
  const [selectedMakeupIndex, setSelectedMakeupIndex] =
    React.useState<number>(0);

  const [isDisplayLast10, setIsDisplayLast10] = React.useState<boolean>(false);

  const [isLiveDataMode, setIsLiveDataMode] = React.useState<boolean>(false);

  const [liveLatency, setLiveLatency] = React.useState<number>(0);

  const [displayMode, setDisplayMode] = React.useState<DisplayMode>(
    DisplayMode.TorqueTurnAreaChart
  );

  // TODO: Optimize useEffect pattern, currently we're utilizing a single useEffect to accomplish a lot of work, we may want
  //       to break out each of these into smaller useEffects to improve performance and alleviate any potential problems/bugs
  // realtimeMessages useEffect
  useEffect(() => {
    if (isLiveDataMode) {
      if (realtimeMessages && realtimeMessages.length > 0) {
        const lastMessage = realtimeMessages.at(-1);

        if (lastMessage && lastMessage.length > 1) {
          const updateMessage = lastMessage[0];
          const updateMessageTimeReceived = lastMessage[1];

          if (updateMessage != null) {
            let _jobId = null;
            let _telemetryJson = JSON.parse(updateMessage.Telemetry);
            if (updateMessage.Type === RecordType.Job) {
              _jobId = _telemetryJson.id;
            } else {
              _jobId = _telemetryJson.jobId;
            }

            // When unsubscribing to a group, sometimes we still have messages queued, this is added protection to filter out processing messages to unrelated jobs
            if (_jobId === selectedJob?.id) {
              const entityId = updateMessage.MessageId;

              switch (updateMessage.Type) {
                case RecordType.JobNote:
                  let newJobNote: string | undefined = (
                    JSON.parse(updateMessage.Telemetry) as JobNoteMessage
                  ).JobNotes;

                  if (
                    checkIfDifferent(selectedJob, {
                      ...selectedJob,
                      jobNotes: newJobNote,
                    })
                  ) {
                    setSelectedJob((j) => {
                      if (!j) {
                        return j;
                      }
                      return {
                        ...j,
                        jobNotes: newJobNote,
                      };
                    });
                  }
                  break;
                case RecordType.CrewMember:
                  let newCrewMember = JSON.parse(
                    updateMessage.Telemetry
                  ) as CrewMember;
                  newCrewMember.id = entityId;

                  let _supervisors = selectedJob?.supervisors;
                  let _inspectors = selectedJob?.inspectors;
                  let _operators = selectedJob?.operators;

                  if (newCrewMember.role === CrewMemberRole.Supervisor) {
                    let matchingSupervisor = _supervisors?.find(
                      (s) => s.id === newCrewMember.id
                    );

                    if (matchingSupervisor) {
                      matchingSupervisor = newCrewMember;
                    } else {
                      _supervisors?.push(newCrewMember);
                    }
                  }
                  if (newCrewMember.role === CrewMemberRole.Inspector) {
                    let matchingInspector = _inspectors?.find(
                      (s) => s.id === newCrewMember.id
                    );

                    if (matchingInspector) {
                      matchingInspector = newCrewMember;
                    } else {
                      _inspectors?.push(newCrewMember);
                    }
                  }
                  if (newCrewMember.role === CrewMemberRole.Operator) {
                    let matchingOperator = _operators?.find(
                      (s) => s.id === newCrewMember.id
                    );

                    if (matchingOperator) {
                      matchingOperator = newCrewMember;
                    } else {
                      _operators?.push(newCrewMember);
                    }
                  }

                  if (
                    checkIfDifferent(selectedJob, {
                      ...selectedJob,
                      supervisors: _supervisors,
                      inspectors: _inspectors,
                      operators: _operators,
                    })
                  ) {
                    setSelectedJob((j) => {
                      if (!j) {
                        return j;
                      }
                      return {
                        ...j,
                        supervisors: _supervisors,
                        inspectors: _inspectors,
                        operators: _operators,
                      };
                    });
                  }
                  break;
                case RecordType.SystemCalibration:
                  let newSystemCalibration = JSON.parse(
                    updateMessage.Telemetry
                  ) as SystemCalibration;
                  newSystemCalibration.id = entityId;

                  if (
                    checkIfDifferent(selectedJob, {
                      ...selectedJob,
                      systemCalibration: newSystemCalibration,
                    })
                  ) {
                    setSelectedJob((j) => {
                      if (!j) {
                        return j;
                      }
                      return {
                        ...j,
                        systemCalibration: newSystemCalibration,
                      };
                    });
                  }
                  break;
                case RecordType.Makeup:
                  // Update the Selected Job's Makeups
                  let newJobMakeup = JSON.parse(
                    updateMessage.Telemetry
                  ) as Makeup;
                  newJobMakeup.id = entityId;

                  let _makeups =
                    selectedJob && selectedJob.makeups
                      ? _.cloneDeep(selectedJob.makeups)
                      : [];
                  let _matchingMakeup = _makeups?.find(
                    (makeup) => makeup.id === newJobMakeup.id
                  );

                  if (_matchingMakeup) {
                    newJobMakeup.finalMakeup = _matchingMakeup.finalMakeup;
                    newJobMakeup.finalMakeupId = _matchingMakeup.finalMakeupId;

                    _matchingMakeup = newJobMakeup;
                  } else {
                    _makeups?.push(newJobMakeup);

                    if (
                      checkIfDifferent(selectedJob, {
                        ...selectedJob,
                        makeups: _makeups,
                      })
                    ) {
                      setSelectedJob((j) => {
                        if (!j) {
                          return j;
                        }
                        return {
                          ...j,
                          makeups: _makeups,
                        };
                      });
                    }
                  }

                  // Update the Selected Makeup
                  let newSelectedMakeup = JSON.parse(
                    updateMessage.Telemetry
                  ) as Makeup;
                  newSelectedMakeup.id = entityId;

                  if (!(selectedMakeup?.id === newSelectedMakeup.id)) {
                    if (!makeupByIdIsLoading) {
                      getMakeupById({ id: newSelectedMakeup.id });
                    }
                  }

                  if (
                    selectedMakeup &&
                    selectedMakeup.id === newSelectedMakeup.id
                  ) {
                    if (
                      selectedMakeup.makeupTelemetry &&
                      selectedMakeup.makeupTelemetry.length > 0
                    ) {
                      newSelectedMakeup.makeupTelemetry = _.cloneDeep(
                        selectedMakeup.makeupTelemetry
                      );
                    } else {
                      newSelectedMakeup.makeupTelemetry = [];
                    }

                    newSelectedMakeup.finalMakeup = _.cloneDeep(
                      selectedMakeup.finalMakeup
                    );
                    newSelectedMakeup.finalMakeupId = _.cloneDeep(
                      selectedMakeup.finalMakeup?.id
                    );
                  } else if (newSelectedMakeup && newSelectedMakeup.jobId) {
                    if (dbMakeupTelemetry && dbMakeupTelemetry.length > 0) {
                      newSelectedMakeup.makeupTelemetry =
                        _.cloneDeep(dbMakeupTelemetry);
                    } else {
                      if (!matchingTelemetryIsLoading) {
                        getMatchingTelemetry({
                          jobId: newSelectedMakeup.jobId,
                          makeupNumber: newSelectedMakeup.makeupNumber,
                          graphNumber: newSelectedMakeup.graphNumber,
                        });
                      }
                    }
                    if (dbFinalMakeup) {
                      newSelectedMakeup.finalMakeup =
                        _.cloneDeep(dbFinalMakeup);
                      newSelectedMakeup.finalMakeupId = _.cloneDeep(
                        dbFinalMakeup.id
                      );
                    } else {
                      if (!matchingFinalMakeupsIsLoading) {
                        getMatchingFinalMakeups({
                          jobId: newSelectedMakeup.jobId,
                          makeupNumber: newSelectedMakeup.makeupNumber,
                          graphNumber: newSelectedMakeup.graphNumber,
                        });
                      }
                    }
                  }

                  if (checkIfDifferent(selectedMakeup, newSelectedMakeup)) {
                    setSelectedMakeup(newSelectedMakeup);
                  }

                  handleLiveDataSync();

                  break;
                case RecordType.MakeupTelemetry:
                  let newMakeupTelemetry = JSON.parse(
                    updateMessage.Telemetry
                  ) as MakeupTelemetry;
                  newMakeupTelemetry.id = entityId;

                  // console.log(` -- ${JSON.stringify(newMakeupTelemetry)}`);

                  if (
                    (!selectedMakeup ||
                      !(
                        selectedMakeup.makeupNumber ===
                          newMakeupTelemetry.makeupNumber &&
                        selectedMakeup.graphNumber ===
                          newMakeupTelemetry.graphNumber
                      )) &&
                    newMakeupTelemetry &&
                    newMakeupTelemetry.jobId
                  ) {
                    if (!matchingMakeupIsLoading) {
                      // setFetchingMatchingMakeup(true);
                      getMatchingMakeup({
                        jobId: newMakeupTelemetry.jobId,
                        makeupNumber: newMakeupTelemetry.makeupNumber,
                        graphNumber: newMakeupTelemetry.graphNumber,
                      });
                    }
                  } else if (selectedMakeup) {
                    setLiveLatency(
                      newMakeupTelemetry.utcDateTime != null
                        ? updateMessageTimeReceived -
                            new Date(newMakeupTelemetry.utcDateTime).getTime()
                        : 0
                    );

                    newMakeupTelemetry.makeupId = _.cloneDeep(
                      selectedMakeup.id
                    );
                    let matchingLiveMT = _.cloneDeep(liveMakeupTelemetry)?.find(
                      (lmt) => lmt.id === newMakeupTelemetry.id
                    );

                    if (matchingLiveMT) {
                      matchingLiveMT = newMakeupTelemetry;
                    } else if (liveMakeupTelemetry[-1] !== newMakeupTelemetry) {
                      if (
                        checkIfDifferent(liveMakeupTelemetry, [
                          ...liveMakeupTelemetry,
                          newMakeupTelemetry,
                        ])
                      ) {
                        setLiveMakeupTelemetry((prev) => [
                          ...prev,
                          newMakeupTelemetry,
                        ]);
                      }
                    }
                  }

                  break;
                case RecordType.FinalMakeup:
                  let newFinalMakeup = JSON.parse(
                    updateMessage.Telemetry
                  ) as FinalMakeup;
                  newFinalMakeup.id = entityId;

                  if (
                    (!selectedMakeup ||
                      !(
                        selectedMakeup.makeupNumber ===
                          newFinalMakeup.makeupNumber &&
                        selectedMakeup.graphNumber ===
                          newFinalMakeup.graphNumber
                      )) &&
                    newFinalMakeup &&
                    newFinalMakeup.jobId
                  ) {
                    if (!matchingMakeupIsLoading) {
                      getMatchingMakeup({
                        jobId: newFinalMakeup.jobId,
                        makeupNumber: newFinalMakeup.makeupNumber,
                        graphNumber: newFinalMakeup.graphNumber,
                      });
                    }
                  } else if (selectedMakeup) {
                    setLiveLatency(
                      newFinalMakeup.utcDateTime != null
                        ? updateMessageTimeReceived -
                            new Date(newFinalMakeup.utcDateTime).getTime()
                        : 0
                    );

                    newFinalMakeup.makeupId = _.cloneDeep(selectedMakeup.id);
                    let matchingLiveFM = _.cloneDeep(liveFinalMakeups)?.find(
                      (lfm) => lfm.id === newFinalMakeup.id
                    );

                    if (matchingLiveFM) {
                      matchingLiveFM = newFinalMakeup;
                    } else if (liveFinalMakeups[-1] !== newFinalMakeup) {
                      if (
                        checkIfDifferent(liveFinalMakeups, [
                          ...liveFinalMakeups,
                          newFinalMakeup,
                        ])
                      ) {
                        setLiveFinalMakeups((prev) => [
                          ...prev,
                          newFinalMakeup,
                        ]);
                      }
                    }
                  }

                  break;
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dbFinalMakeup,
    dbMakeupTelemetry,
    liveFinalMakeups,
    liveMakeupTelemetry,
    realtimeMessages,
    selectedJob,
    selectedMakeup,
  ]);

  // isLiveDataMode useEffect
  useEffect(() => {
    if (isLiveDataMode) {
      // Turning Live-Data Mode ON
      if (id && id.length > 0) {
        if (isDisplayLast10 === true) {
          setIsDisplayLast10(false);
        }

        if (webPubSubClient) {
          joinGroup(id);
          if (!jobByIdIsLoading) {
            getJobById({ id: id });
          }
        }
      }
    } else {
      // Turning Live-Data Mode OFF
      if (id) {
        leaveGroup(id);
      }

      handleResetLiveDataState();
    }

    return () => {
      if (id) {
        leaveGroup(id);
        handleResetLiveDataState();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isDisplayLast10, isLiveDataMode, webPubSubClient]);

  // id useEffect
  useEffect(() => {
    if (id) {
      handleResetState();
      if (!jobByIdIsLoading) {
        getJobById({ id: id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // jobByIdResults useEffect
  useEffect(() => {
    if (jobByIdResults) {
      if (checkIfDifferent(selectedJob, jobByIdResults)) {
        if (selectedJob?.id !== jobByIdResults.id) {
          setSelectedMakeupIndex(0);
        }

        setSelectedJob(jobByIdResults);
      }

      // Reset State to null
      setJobByIdResults(null);
    }
  }, [jobByIdResults, selectedJob]);

  // makeupByIdResults useEffect
  useEffect(() => {
    if (makeupByIdResults) {
      if (checkIfDifferent(selectedMakeup, makeupByIdResults)) {
        setSelectedMakeup(makeupByIdResults);
      }

      if (isDisplayLast10) {
        if (selectedJob && selectedMakeup) {
          if (!last10MakeupsByIdIsLoading) {
            getLast10MakeupsById({
              jobId: selectedJob.id,
              currentMakeupId: makeupByIdResults.id,
            });
          }
        }
      }

      // Reset State to null
      setMakeupByIdResults(null);
    } else if (matchingMakeupResults) {
      if (checkIfDifferent(selectedMakeup, matchingMakeupResults)) {
        setSelectedMakeup(matchingMakeupResults);
      }

      if (isDisplayLast10) {
        if (selectedJob && selectedMakeup) {
          if (!last10MakeupsByIdIsLoading) {
            getLast10MakeupsById({
              jobId: selectedJob.id,
              currentMakeupId: matchingMakeupResults.id,
            });
          }
        }
      }

      // Reset State to null
      setMatchingMakeupResults(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDisplayLast10,
    makeupByIdResults,
    matchingMakeupResults,
    selectedJob,
    selectedMakeup,
  ]);

  // last10MakeupsByIdResults useEffect
  useEffect(() => {
    if (last10MakeupsByIdResults) {
      if (
        last10MakeupsByIdResults &&
        last10MakeupsByIdResults.length > 0 &&
        checkIfDifferent(selectedLast10Makeup, last10MakeupsByIdResults)
      ) {
        setSelectedLast10Makeup(last10MakeupsByIdResults);
      }

      // Reset State to null
      setLast10MakeupsByIdResults(null);
    }
  }, [last10MakeupsByIdResults, selectedLast10Makeup]);

  // isDisplayLast10 useEffect
  useEffect(() => {
    if (isDisplayLast10) {
      if (selectedJob && selectedMakeup) {
        if (!last10MakeupsByIdIsLoading) {
          getLast10MakeupsById({
            jobId: selectedJob.id,
            currentMakeupId: selectedMakeup.id,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayLast10, selectedJob, selectedMakeup]);

  // matchingTelemetryResults useEffect
  useEffect(() => {
    let dbMakeupTelemetryWasNull = dbMakeupTelemetry === null;

    if (matchingTelemetryResults) {
      if (checkIfDifferent(dbMakeupTelemetry, matchingTelemetryResults)) {
        setDBMakeupTelemetry(matchingTelemetryResults);
      }

      // Reset State to null
      setMatchingTelemetryResults(null);
    } else if (!dbMakeupTelemetryWasNull) {
      setDBMakeupTelemetry(null);
    }
  }, [dbMakeupTelemetry, matchingTelemetryResults]);

  // matchingFinalMakeupsResults useEffect
  useEffect(() => {
    let dbFinalMakeupWasNull = dbFinalMakeup === null;

    if (matchingFinalMakeupsResults) {
      if (checkIfDifferent(dbFinalMakeup, matchingFinalMakeupsResults)) {
        if (matchingFinalMakeupsResults.length > 0) {
          setDBFinalMakeup(matchingFinalMakeupsResults[0]);
        }
      }

      // Reset State to null
      setMatchingFinalMakeupsResults(null);
    } else if (!dbFinalMakeupWasNull) {
      setDBFinalMakeup(null);
    }
  }, [dbFinalMakeup, matchingFinalMakeupsResults]);

  // selectedJob useEffect
  useEffect(() => {
    if (selectedJob && !isLiveDataMode) {
      let selectedMakeupWasNull = selectedMakeup === null;

      if (selectedJob?.makeups && selectedJob.makeups.length > 0) {
        if (!makeupByIdIsLoading) {
          getMakeupById({ id: selectedJob.makeups[selectedMakeupIndex].id });
          modalContext.closeModal();
        }
      } else if (!selectedMakeupWasNull) {
        setSelectedMakeup(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveDataMode, selectedJob, selectedMakeupIndex]);

  // selectedMakeup useEffect
  useEffect(() => {
    if (selectedMakeup) {
      handleSetMakeupIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMakeup]);

  // liveMakeupTelemetry and liveFinalMakeups useEffect
  useEffect(() => {
    handleLiveDataSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMakeupTelemetry, liveFinalMakeups]);

  // Toggle Slide Pane Menu Function
  const handleToggleLeftPane = () => {
    setIsSlidePanelOpen(!isSlidePanelOpen);
  };

  // Move to the Next Makeup
  const handleMoveToNextMakeup = () => {
    if (
      selectedJob?.makeups &&
      selectedMakeupIndex < selectedJob?.makeups.length - 1
    ) {
      if (!makeupByIdIsLoading) {
        getMakeupById({ id: selectedJob?.makeups[selectedMakeupIndex + 1].id });
      }
    }
  };

  // Move to the Previous Makeup
  const handleMoveToPrevMakeup = () => {
    if (selectedJob?.makeups && selectedMakeupIndex > 0) {
      if (!makeupByIdIsLoading) {
        getMakeupById({ id: selectedJob?.makeups[selectedMakeupIndex - 1].id });
      }
    }
  };

  const handleResetState = () => {
    if (
      jobByIdResults !== null ||
      last10MakeupsByIdResults !== null ||
      makeupByIdResults !== null ||
      matchingMakeupResults !== null ||
      matchingTelemetryResults !== null ||
      matchingFinalMakeupsResults !== null ||
      selectedJob !== null ||
      selectedMakeup !== null ||
      selectedLast10Makeup !== null ||
      isSlidePanelOpen !== true ||
      selectedPane !== 0 ||
      selectedMakeupIndex !== 0 ||
      isDisplayLast10 !== false ||
      isLiveDataMode !== false ||
      displayMode !== DisplayMode.TorqueTurnAreaChart ||
      jobByIdResults !== null ||
      last10MakeupsByIdResults !== null ||
      makeupByIdResults !== null ||
      matchingMakeupResults !== null ||
      matchingTelemetryResults !== null ||
      matchingFinalMakeupsResults !== null
    ) {
      setJobByIdResults(null);
      setLast10MakeupsByIdResults(null);
      setMakeupByIdResults(null);
      setMatchingMakeupResults(null);
      setMatchingTelemetryResults(null);
      setMatchingFinalMakeupsResults(null);
      setSelectedJob(null);
      setSelectedMakeup(null);
      setSelectedLast10Makeup(null);
      setIsSlidePanelOpen(true);
      setSelectedPane(0);
      setSelectedMakeupIndex(0);
      setIsDisplayLast10(false);
      setIsLiveDataMode(false);
      setDisplayMode(DisplayMode.TorqueTurnAreaChart);
      setJobByIdResults(null);
      setLast10MakeupsByIdResults(null);
      setMakeupByIdResults(null);
      setMatchingMakeupResults(null);
      setMatchingTelemetryResults(null);
      setMatchingFinalMakeupsResults(null);

      handleResetLiveDataState();
    }
  };

  const handleResetLiveDataState = () => {
    if (
      dbMakeupTelemetry !== null ||
      dbFinalMakeup !== null ||
      liveMakeupTelemetry.length > 0 ||
      liveFinalMakeups.length > 0 ||
      liveLatency !== 0
    ) {
      setDBMakeupTelemetry(null);
      setDBFinalMakeup(null);
      setLiveMakeupTelemetry([]);
      setLiveFinalMakeups([]);
      setLiveLatency(0);
    }
  };

  const handleLiveDataSync = () => {
    if (isLiveDataMode) {
      let jobDeepClone = _.cloneDeep(selectedJob);
      let makeupDeepClone = _.cloneDeep(selectedMakeup);

      // Sync Selected Job with the Live Final Makeup data
      jobDeepClone?.makeups?.forEach((makeup) => {
        let matchingFMs = _.cloneDeep(liveFinalMakeups)
          .filter(
            (fm) =>
              fm.makeupNumber === makeup.makeupNumber &&
              fm.graphNumber === makeup.graphNumber
          )
          .sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
        if (matchingFMs && matchingFMs.length > 0) {
          makeup.finalMakeup = matchingFMs[0];
          makeup.finalMakeupId = matchingFMs[0].id;
        }
      });

      if (makeupDeepClone) {
        // Sync Selected Makeup with the Live Final Makeup Data
        let matchingSMFMs = _.cloneDeep(liveFinalMakeups)
          .filter((fm) => fm.makeupId === makeupDeepClone?.id)
          .sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
        if (matchingSMFMs && matchingSMFMs.length > 0) {
          makeupDeepClone.finalMakeup = matchingSMFMs[0];
          makeupDeepClone.finalMakeupId = matchingSMFMs[0].id;
        }

        // Sync Selected Makeup with the Live Makeup Telemetry Data
        let matchingSMMT = liveMakeupTelemetry
          .filter(
            (mt) =>
              mt.makeupNumber === makeupDeepClone?.makeupNumber &&
              mt.graphNumber === makeupDeepClone?.graphNumber
          )
          .sort((a, b) => a.turns - b.turns);
        if (matchingSMMT && matchingSMMT.length > 0) {
          if (
            makeupDeepClone.makeupTelemetry &&
            makeupDeepClone.makeupTelemetry.length > 0
          ) {
            let matchingSet = new Map(matchingSMMT.map((mt) => [mt.id, mt]));
            let combinedMakeupTelemetry = matchingSMMT.concat(
              _.cloneDeep(makeupDeepClone.makeupTelemetry).filter(
                (mt) => !matchingSet.has(mt.id)
              )
            );

            combinedMakeupTelemetry.sort((a, b) => a.turns - b.turns);
            makeupDeepClone.makeupTelemetry = combinedMakeupTelemetry;
          } else {
            makeupDeepClone.makeupTelemetry = matchingSMMT;
          }
        }
      }

      if (checkIfDifferent(selectedJob, jobDeepClone)) {
        setSelectedJob(jobDeepClone);
      }

      if (checkIfDifferent(selectedMakeup, makeupDeepClone)) {
        setSelectedMakeup(makeupDeepClone);
      }
    }
  };

  const handleSetMakeupIndex = () => {
    // Update the Selected Makeup Index
    if (selectedJob && selectedMakeup) {
      let makeupIndex =
        selectedJob.makeups?.findIndex(
          (makeup) => makeup.id === selectedMakeup.id
        ) ?? 0;

      if (
        checkIfDifferent(selectedMakeupIndex, makeupIndex) &&
        makeupIndex !== -1
      ) {
        setSelectedMakeupIndex(makeupIndex);
      }
    } else {
      setSelectedMakeupIndex(0);
    }
  };

  const checkIfDifferent = (objA: any, objB: any): boolean => {
    return JSON.stringify(objA) !== JSON.stringify(objB);
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 75px)",
      }}
    >
      {/* Body */}
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 70px)", // 100% - Footer Height
        }}
      >
        {/* Left Group */}
        <Box
          sx={{
            backgroundColor: "var(--parker-cyan-accent)",
            padding: "0.5em",
            height: "calc(100% - 1em)",
            width: isSlidePanelOpen ? "calc(16% - 1em)" : "calc(2% - 1em)",
          }}
        >
          {/* Pane Arrows */}
          <Box
            sx={isSlidePanelOpen ? { float: "right" } : { textAlign: "center" }}
          >
            <Typography
              sx={{
                color: "white",
                "&:hover": {
                  userSelect: "none",
                  cursor: "pointer",
                  opacity: 0.75,
                },
              }}
              onClick={handleToggleLeftPane}
            >
              {isSlidePanelOpen ? "<<" : ">>"}
            </Typography>
          </Box>

          {/* Side Pane Container */}
          {isSlidePanelOpen ? (
            <Box
              sx={{
                height: "100%", // 100% - Footer Height -
                width: "100%",
              }}
            >
              {/* Pane Menu */}
              <Box
                sx={{
                  width: "100%",
                  height: "16%",
                }}
              >
                <PaneMenu
                  setSelectedPane={setSelectedPane}
                  selectedPane={selectedPane}
                  job={selectedJob}
                />
              </Box>

              {/* Selected Pane */}
              <Box
                sx={{
                  padding: "0.5em 0 0 0",
                  width: "100%",
                  height: "calc(84% - 0.5em)", // % - margin
                }}
              >
                {selectedPane === 0 && (
                  <OngoingJobs selectedJob={selectedJob} />
                )}
                {selectedPane === 1 && <JobDetails job={selectedJob} />}
                {selectedPane === 2 && (
                  <SystemCalibrationComponent job={selectedJob} />
                )}
                {selectedPane === 3 && <JobNotes job={selectedJob} />}
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        {/* Middle Group */}
        <Box
          sx={{
            margin: "1em",
            width: isSlidePanelOpen ? "calc(66% - 2em)" : "calc(80% - 2em)",
          }}
        >
          <Box
            sx={{
              margin: "0 0 1em 0",
            }}
          >
            <MakeupMetadata job={selectedJob} makeup={selectedMakeup} />
          </Box>
          <Graph
            makeup={selectedMakeup}
            last10Makeups={selectedLast10Makeup}
            isDisplayLast10={isDisplayLast10}
            isLiveDataMode={isLiveDataMode}
            displayMode={displayMode}
          />
        </Box>

        {/* Right Group */}
        <Box
          sx={{
            margin: "1em 1em 1em 0",
            width: "calc(18% - 1em)",
          }}
        >
          <MakeupSummary makeup={selectedMakeup} />
        </Box>
      </Box>

      {/* Footer */}
      <ControlsFooter
        setIsDisplayLast10={setIsDisplayLast10}
        setSelectedLast10Makeup={setSelectedLast10Makeup}
        setIsLiveDataMode={setIsLiveDataMode}
        setDisplayMode={setDisplayMode}
        handleMoveToNextMakeup={handleMoveToNextMakeup}
        handleMoveToPrevMakeup={handleMoveToPrevMakeup}
        makeupIndex={selectedMakeupIndex}
        makeupsLength={
          selectedJob && selectedJob.makeups != null
            ? selectedJob.makeups.length
            : 0
        }
        isDisplayLast10={isDisplayLast10}
        isLiveDataMode={isLiveDataMode}
        liveLatency={liveLatency}
        displayMode={displayMode}
      />
    </Box>
  );
};
