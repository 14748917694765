import { Box, Grid, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import { ToGUIDSignature } from "../Utilities/GUIDUtility";
import { Makeup } from "../Interfaces/Makeup";
import { CustomTooltip } from "./CustomTooltip";
import { DeviceType, MakeupType } from "../Interfaces/Base";

export const MakeupMetadata = ({
  job,
  makeup,
}: {
  job: Job | null;
  makeup: Makeup | null;
}) => {
  return (
    <Box>
      {/* Metadata Group 1 */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "0.75em",
          padding: "1em",
          color: "var(--parker-cyan)",
          fontWeight: "bold",
          marginBottom: "1em",
        }}
      >
        {/* Top-Half */}
        <Grid sx={{ marginBottom: "0.5em" }} container columns={4}>
          {/* Job Id */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Job Id:
            </Typography>
            <CustomTooltip title={job?.id ?? "-"}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {ToGUIDSignature(job?.id || "")}
              </Typography>
            </CustomTooltip>
          </Grid>

          {/* Make-Up */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Make-Up:
            </Typography>
            <Typography>{makeup?.makeupNumber ?? "-"}</Typography>
          </Grid>

          {/* Graph No */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Graph No:
            </Typography>
            <Typography>{makeup?.graphNumber ?? "-"}</Typography>
          </Grid>

          {/* Tally No */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Tally No:
            </Typography>
            <Typography>{makeup?.finalMakeup?.tallyNumber ?? "-"}</Typography>
          </Grid>
        </Grid>

        {/* Bottom-Half */}
        <Grid container columns={4}>
          {/* Makeup Progress Status */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              {makeup?.finalMakeup
                ? `${
                    makeup?.type === MakeupType.Makeup
                      ? "Makeup"
                      : makeup?.type === MakeupType.Breakout
                      ? "Breakout"
                      : "UNKNOWN TYPE"
                  } Completed`
                : `${
                    makeup?.type === MakeupType.Makeup
                      ? "Makeup"
                      : makeup?.type === MakeupType.Breakout
                      ? "Breakout"
                      : "UNKNOWN TYPE"
                  } in Progress`}
            </Typography>
          </Grid>

          {/* Device Type */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Device:{" "}
            </Typography>
            <Typography>
              {job?.systemCalibration?.device === DeviceType.CRT
                ? "CRT"
                : job?.systemCalibration?.device === DeviceType.PowerTong
                ? "Power Tong"
                : "-"}
            </Typography>
          </Grid>

          {/* Date */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Date:{" "}
            </Typography>
            <Typography>
              {makeup?.dateTime
                ? new Date(makeup.dateTime).toLocaleDateString()
                : "-"}
            </Typography>
          </Grid>

          {/* Time */}
          <Grid
            sx={{
              padding: "0 0.5em",
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Time:{" "}
            </Typography>
            <Typography>
              {makeup?.dateTime
                ? new Date(makeup.dateTime).toLocaleTimeString()
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Metadata Group 2 */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "0.75em",
          padding: "1em",
          color: "var(--parker-cyan)",
          fontWeight: "bold",
        }}
      >
        {/* Top-Half */}
        <Grid sx={{ marginBottom: "0.5em" }} container columns={3}>
          {/* Torque */}
          <Grid
            sx={{ width: "25%", display: "flex", alignItems: "center" }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Torque:
            </Typography>
            <Typography>
              {(makeup?.makeupTelemetry &&
                makeup.makeupTelemetry.length > 0 &&
                makeup.makeupTelemetry.reduce((newest, mt) =>
                  mt.dateTime > newest.dateTime ? mt : newest
                ).torque) ??
                "-"}
            </Typography>
          </Grid>

          {/* Peak Torque */}
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Peak Torque:
            </Typography>
            <Typography>
              {(makeup?.makeupTelemetry &&
                makeup.makeupTelemetry.length > 0 &&
                makeup.makeupTelemetry.reduce((newest, mt) =>
                  mt.dateTime > newest.dateTime ? mt : newest
                ).peakTorque) ??
                "-"}
            </Typography>
          </Grid>

          {job?.systemCalibration?.device === DeviceType.CRT ? (
            <>
              {/* Hook Load */}
              <Grid
                sx={{ display: "flex", alignItems: "center" }}
                item={true}
                xs={1}
              >
                <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
                  Hook Load:
                </Typography>
                <Typography>
                  {(makeup?.makeupTelemetry &&
                    makeup.makeupTelemetry.length > 0 &&
                    makeup.makeupTelemetry.reduce((newest, mt) =>
                      mt.dateTime > newest.dateTime ? mt : newest
                    ).hookLoad) ??
                    "-"}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              {/* Arm Length */}
              <Grid
                sx={{ display: "flex", alignItems: "center" }}
                item={true}
                xs={1}
              >
                <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
                  Arm Length:
                </Typography>
                <Typography>
                  {(makeup?.makeupTelemetry &&
                    makeup.makeupTelemetry.length > 0 &&
                    makeup.makeupTelemetry.reduce((newest, mt) =>
                      mt.dateTime > newest.dateTime ? mt : newest
                    ).armLength) ??
                    "-"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        {/* Bottom-Half */}
        <Grid
          sx={{ display: "flex", alignItems: "center" }}
          container
          columns={3}
        >
          {/* Turns */}
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Turns:
            </Typography>
            <Typography>
              {(makeup?.makeupTelemetry &&
                makeup.makeupTelemetry.length > 0 &&
                makeup.makeupTelemetry.reduce((newest, mt) =>
                  mt.dateTime > newest.dateTime ? mt : newest
                ).turns) ??
                "-"}
            </Typography>
          </Grid>

          {/* RPM */}
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              RPM:
            </Typography>
            <Typography>
              {(makeup?.makeupTelemetry &&
                makeup.makeupTelemetry.length > 0 &&
                makeup.makeupTelemetry.reduce((newest, mt) =>
                  mt.dateTime > newest.dateTime ? mt : newest
                ).rpm) ??
                "-"}
            </Typography>
          </Grid>

          {/* Max RPM */}
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item={true}
            xs={1}
          >
            <Typography sx={{ marginRight: "0.5em", fontWeight: "bold" }}>
              Max RPM:
            </Typography>
            <Typography>
              {(makeup?.makeupTelemetry &&
                makeup.makeupTelemetry.length > 0 &&
                makeup.makeupTelemetry.reduce((newest, mt) =>
                  mt.dateTime > newest.dateTime ? mt : newest
                ).maxRPM) ??
                "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
