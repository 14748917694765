import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  PaginationItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Job } from "../Interfaces/Job";
import { useEffect, useState } from "react";
import { JobCard } from "./JobCard";
import CloseIcon from "@mui/icons-material/Close";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { useGetJobsLight } from "../Hooks/useGetJobsLight";
import { useGetMatchingJobsLight } from "../Hooks/useGetMatchingJobsLight";
import { v4 } from "uuid";
import _ from "lodash";

// TODO: Odd bug right now where if you're scrolling and you get a tooltip to pop up, it prevents you from scrolling
export const OngoingJobs = ({ selectedJob }: { selectedJob: Job | null }) => {
  const {
    mutate: getAllJobs,
    data: allJobsData,
    isError: isAllJobsError,
    isLoading: isAllJobsLoading,
  } = useGetJobsLight();

  const {
    mutate: getMatchingJobs,
    data: matchingJobsData,
    isError: isMatchingJobsError,
    isLoading: isMatchingJobsLoading,
  } = useGetMatchingJobsLight();

  const [matchingJobs, setMatchingJobs] = useState<Job[]>([]);
  const [allJobs, setAllJobs] = useState<Job[]>([]);

  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    getAllJobs({ pageNumber: 1, pageSize: 25 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matchingJobs && matchingJobs.length > 0 && selectedJob) {
      let matchingJobsClone = _.cloneDeep(matchingJobs);

      let matchIndex = matchingJobsClone.findIndex(
        (j) => j.id === selectedJob?.id
      );
      if (matchIndex !== -1) {
        matchingJobsClone.splice(matchIndex, 1, selectedJob);
      }

      let isDifferentMatchingJob =
        JSON.stringify(matchingJobs) !== JSON.stringify(matchingJobsClone);
      if (isDifferentMatchingJob) {
        setMatchingJobs(matchingJobsClone);
      }
    }

    if (allJobs && allJobs.length > 0 && selectedJob) {
      let allJobsClone = _.cloneDeep(allJobs);

      let matchIndex = allJobsClone.findIndex((j) => j.id === selectedJob?.id);
      if (matchIndex !== -1) {
        allJobsClone.splice(matchIndex, 1, selectedJob);
      }

      if (JSON.stringify(allJobs) !== JSON.stringify(allJobsClone)) {
        setAllJobs(allJobsClone);
      }
    }
  }, [matchingJobs, allJobs, selectedJob]);

  useEffect(() => {
    if (isMatchingJobsError) {
      setErrorMessage("Error fetching matching jobs");
      setShowError(true);
    } else if (isAllJobsError) {
      setErrorMessage("Error fetching all jobs");
      setShowError(true);
    }
  }, [isMatchingJobsError, isAllJobsError]);

  useEffect(() => {
    if (matchingJobsData) {
      setNumberOfPages(matchingJobsData.totalPages ?? 0);
      setMatchingJobs(matchingJobsData.result);
    }
  }, [matchingJobsData]);

  useEffect(() => {
    if (allJobsData) {
      setNumberOfPages(allJobsData.totalPages ?? 0);
      setAllJobs(allJobsData.result);
    }
  }, [allJobsData]);

  const handleSearch = () => {
    setShowError(false);
    if (searchString.length > 0) {
      setSearchMode(true);
      getMatchingJobs({
        searchString,
        pageNumber: 1,
        pageSize: 25,
      });
      setPageNumber(1);
    } else {
      handleClearSearch();
    }
  };

  const handlePaginateSearch = (pageNumber: number) => {
    setPageNumber(pageNumber);
    getAllJobs({ pageNumber: pageNumber, pageSize: 25 });
  };

  const handleClearSearch = () => {
    setSearchMode(false);
    setSearchString("");
    getAllJobs({ pageNumber: 1, pageSize: 25 });
  };

  const handleCloseErrorMessage = () => {
    setShowError(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {/* Error Popup */}
      <Snackbar
        sx={{
          color: "white",
          "& .MuiAlert-icon": {
            color: "white",
          },
        }}
        open={showError}
        onClose={(event) => {
          event.preventDefault();
        }}
      >
        <Alert
          sx={{ backgroundColor: "#D74545", color: "white" }}
          severity='error'
          action={
            <>
              <IconButton
                sx={{ color: "white" }}
                size='small'
                onClick={handleCloseErrorMessage}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </>
          }
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Ongoing Jobs */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          borderRadius: "0.75em",
          color: "var(--parker-cyan)",
        }}
      >
        {/* Title */}
        <Box
          sx={{
            height: "5%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              width: "100%",
              fontWeight: "bold",
            }}
            variant='h6'
          >
            Jobs
          </Typography>
        </Box>

        {/* Search Box */}
        <Box
          sx={{
            height: "8%",
            display: "flex",
            padding: "0 0 0.5em 0.5em",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "100%" }}
            size='small'
            placeholder='Customer, Well, or Location'
            value={searchString}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
          {searchString.length > 0 && (
            <IconButton
              sx={{ color: "var(--parker-cyan)" }}
              size='small'
              onClick={handleClearSearch}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          )}

          <IconButton
            sx={{ color: "var(--parker-cyan)" }}
            size='small'
            onClick={handleSearch}
          >
            <SearchIcon fontSize='small' />
          </IconButton>
        </Box>

        {/* Results */}
        <Box
          sx={{
            display: "flex",
            height: "calc(83% - 1.5em)",
            width: "100%",
            overflowY: "scroll",
            flexDirection: "column",
            ".job-card:nth-of-type(odd)": {
              backgroundColor: "var(--parker-grey-accent)",
            },
          }}
        >
          {isAllJobsLoading || isMatchingJobsLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : searchMode ? (
            matchingJobs && matchingJobs.length > 0 ? (
              matchingJobs.map((job, index) => (
                <JobCard
                  key={v4()}
                  job={job}
                  isSelected={
                    selectedJob && selectedJob.id === job.id ? true : false
                  }
                />
              ))
            ) : (
              <Typography sx={{ textAlign: "center" }}>
                No Results Found
              </Typography>
            )
          ) : allJobs && allJobs.length > 0 ? (
            allJobs.map((job, index) => (
              <JobCard
                key={v4()}
                job={job}
                isSelected={
                  selectedJob && selectedJob.id === job.id ? true : false
                }
              />
            ))
          ) : (
            <Typography sx={{ textAlign: "center" }}>
              No Results Found
            </Typography>
          )}
        </Box>

        {/* Page Numbers */}
        <Box
          sx={{
            height: "4%",
            width: "100%",
            margin: "0.5em 0",
          }}
        >
          {numberOfPages > 0 && (
            <Pagination
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              page={pageNumber}
              count={numberOfPages}
              size='small'
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  onClick={() => {
                    handlePaginateSearch(item.page ?? 1);
                  }}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
