import { Box, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import { ToGUIDSignature } from "../Utilities/GUIDUtility";
import { CustomTooltip } from "./CustomTooltip";
import { DeviceType } from "../Interfaces/Base";

export const SystemCalibration = ({ job }: { job: Job | null }) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* System Calibration */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
            variant='h6'
          >
            System Calibration
          </Typography>
        </Box>

        {/* Job Id */}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              marginRight: "0.5em",
              fontWeight: "bold",
            }}
          >
            Job Id:
          </Typography>
          <CustomTooltip title={job?.id || ""}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {ToGUIDSignature(job?.id)}
            </Typography>
          </CustomTooltip>
        </Box>

        {/* Separation Line */}
        <Box
          sx={{
            margin: "0.5em 0",
            borderBottom: "1px solid var(--parker-cyan-accent)",
          }}
        />

        {job?.systemCalibration?.device === DeviceType.CRT ? (
          // CRT System Calibration Settings
          <>
            {/* Hook Load */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Hook Load
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Hook Load */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Hook Load:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.hookLoad ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.unit ?? ""}
                    </Typography>
                  </Box>
                </Box>

                {/* Unit */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Units:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.unit ?? "-"}
                    </Typography>
                  </Box>
                </Box>

                {/* Alarm */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Alarm:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.armLength1 ?? "-"}
                    </Typography>
                  </Box>
                </Box>

                {/* Hook Load Scale Max */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Scale Max:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.hookLoadScaleMax ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.unit ?? ""}
                    </Typography>
                  </Box>
                </Box>

                {/* Hook Load Scale Min */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Scale Min:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.hookLoadScaleMin ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.unit ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Separation Line */}
            <Box
              sx={{
                margin: "0.5em 0",
                borderBottom: "1px solid var(--parker-cyan-accent)",
              }}
            />

            {/* Torque */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Torque
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Torque */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Torque:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.torque ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>Ft.lbs</Typography>
                  </Box>
                </Box>

                {/* Torque Scale Max */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Scale Max:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.torqueScaleMax ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>Ft.lbs</Typography>
                  </Box>
                </Box>

                {/* Torque Scale Min */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Scale Min:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.torqueScaleMin ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>Ft.lbs</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Separation Line */}
            <Box
              sx={{
                margin: "0.5em 0",
                borderBottom: "1px solid var(--parker-cyan-accent)",
              }}
            />

            {/* Turns */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Turns
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Turns */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Counts/Turns:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.turns ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>RPM</Typography>
                  </Box>
                </Box>

                {/* Turns Scale Max */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Scale Max:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.turnsScaleMax ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>RPM</Typography>
                  </Box>
                </Box>

                {/* Turns Scale Min */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Scale Min:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.turnsScaleMin ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>RPM</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          // Power Tong System Calibration Settings
          <>
            {/* Load Cell 1 */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Load Cell 1
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Sensor Range */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Sensor Range:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.loadCell1SensorRange ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>lbs</Typography>
                  </Box>
                </Box>

                {/* Load Cell C.F. */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Load Cell C.F.:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.loadCell1CF ?? "-"}
                    </Typography>
                  </Box>
                </Box>

                {/* Arm Length */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Arm Length:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.armLength1 ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>in</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Separation Line */}
            <Box
              sx={{
                margin: "0.5em 0",
                borderBottom: "1px solid var(--parker-cyan-accent)",
              }}
            />

            {/* Load Cell 2 */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Load Cell 2
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Sensor Range */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Sensor Range:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.loadCell2SensorRange ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>lbs</Typography>
                  </Box>
                </Box>

                {/* Load Cell C.F. */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Load Cell C.F.:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.loadCell2CF ?? "-"}
                    </Typography>
                  </Box>
                </Box>

                {/* Arm Length */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Arm Length:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.armLength2 ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>in</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Separation Line */}
            <Box
              sx={{
                margin: "0.5em 0",
                borderBottom: "1px solid var(--parker-cyan-accent)",
              }}
            />

            {/* Turns Sensor */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Turns Sensor
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Counts/Turn */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Counts/Turn:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.turnSensorTurns ?? "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Separation Line */}
            <Box
              sx={{
                margin: "0.5em 0",
                borderBottom: "1px solid var(--parker-cyan-accent)",
              }}
            />

            {/* Pressure Sensor */}
            <Box>
              {/* Title */}
              <Box>
                <Typography
                  sx={{
                    marginRight: "0.5em",
                    fontWeight: "bold",
                  }}
                >
                  Pressure Sensor
                </Typography>
              </Box>

              {/* Propeties & Values */}
              <Box>
                {/* Sensor Range */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Sensor Range:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.pressureSensorRange ?? "-"}
                    </Typography>
                  </Box>

                  {/* Units */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "calc(16% - 1em)",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 0.5em",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>psi</Typography>
                  </Box>
                </Box>

                {/* Pressure CF */}
                <Box sx={{ display: "flex" }}>
                  {/* Name */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "62%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        margin: "0 0.5em",
                        fontWeight: "bold",
                        fontSize: ".9rem",
                      }}
                    >
                      Pressure C.F.:
                    </Typography>
                  </Box>

                  {/* Value */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "22%",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: ".9rem" }}>
                      {job?.systemCalibration?.pressureCF ?? "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
