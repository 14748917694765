import { Box } from "@mui/material";
import LOGO from "../Assets/ParkerWellboreBannerWhite.png";
import { Button } from "./Button";
import { useMsal } from "@azure/msal-react";

export const PageHeader = () => {
  const { instance } = useMsal();

  const handleLogOut = async () => {
    await instance.logout();
    instance.setActiveAccount(null);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "75px",
          width: "100%",
          background: "var(--parker-cyan)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            margin: "0 3em",
            color: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "75px",
            }}
          >
            <Box
              component="img"
              width="175px"
              src={LOGO}
              alt="Parker Wellbore"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (window.location.href) {
                  window.location.href = "/";
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Parker Wellbore Torque Turn Remote Viewing System</h2>
            <Box sx={{ marginLeft: "1em" }}>
              <Button onClick={handleLogOut} size="small">
                Log Out
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
