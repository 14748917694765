import { useMsal } from "@azure/msal-react";
import { Button } from "./Components/Button";
import { Box } from "@mui/material";
import LOGO from "./Assets/ParkerWellboreBannerWhite.png";

export const Auth = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.initialize();
      await instance.loginRedirect();
    } catch (e) {
      console.log(`[Auth] Error - ${e}`);
    }
  };

  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          background: "var(--parker-cyan)",
          padding: "4em",
          borderRadius: "1em",
          color: "white",
        }}
      >
        <img width="600px" src={LOGO} alt="Parker Wellbore Logo" />
        <h1>Parker Wellbore Torque Turn Remote Viewing System</h1>
        <Button onClick={handleLogin}>Sign In With Parker Account</Button>
      </Box>
    </Box>
  );
};
