import React from "react";
import { AppRouter } from "./AppRouter";
import { msalConfig } from "./Utilities/MsalUtility";
import { MsalProvider } from "@azure/msal-react";

import { PublicClientApplication } from "@azure/msal-browser";

export const App = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <AppRouter />
    </MsalProvider>
  );
};
