import { Box } from "@mui/material";
import { Makeup } from "../Interfaces/Makeup";
import { OverlayLastTenGraph } from "./Graphs/OverlayLastTenGraph";
import { PrimaryGraph } from "./Graphs/PrimaryGraph";
import { MakeupType } from "../Interfaces/Base";
import { DisplayMode } from "../Interfaces/DisplayMode";

export const Graph = ({
  makeup,
  last10Makeups,
  isDisplayLast10,
  isLiveDataMode,
  displayMode,
}: {
  makeup: Makeup | null;
  last10Makeups: Makeup[] | null;
  isDisplayLast10: boolean;
  isLiveDataMode: boolean;
  displayMode: DisplayMode;
}) => {
  const ShowRefLineValues = true;

  const refLineLabelPosition = "right";
  const refLineLabelXOffset = 10;
  const refLineLabelYOffset = 0;

  const refLineValuePosition = "right";
  const refLineValueXOffset = -74;
  const refLineValueYOffset = -12;

  // Adjust these values to change the number of X/Y-Axis Ticks
  const xTickCount = 10;

  let xMaxDataValue = 3;
  let xMinDataValue = 0;

  let xMaxValue = 3;
  let xMinValue = 0;

  let yMaxValue = 0;
  let yMinValue = 0;

  let xTicks: number[] = [];
  let yTicks: number[] = [];

  let yDomainMax = null;

  let MaxTorque = 0;
  let RefTorque = 0;
  let shoulderOffset = "";
  let shoulderTurns: number | undefined = 0;
  let last10ShoulderOffets: string[] = [""];

  let optTorque = 100;

  let xAxisName = "Turns";
  let yAxisName = "Torque";

  // TODO: Will need to refactor eventually to support different X and Y axis values besides Torque/Turns (tied into the "Display Mode" feature)
  if (makeup) {
    if (makeup.makeupTelemetry && makeup.makeupTelemetry.length > 0) {
      xMaxDataValue = Math.max(...makeup.makeupTelemetry.map((t) => t.turns));
      xMinDataValue = Math.min(...makeup.makeupTelemetry.map((t) => t.turns));
    }

    xMaxValue = makeup.maxTurns;
    xMinValue = makeup.minTurns;

    yMaxValue = makeup.maxTorque;

    MaxTorque = makeup.maxTorque;
    RefTorque = makeup.refTorque;

    if (makeup.finalMakeup) {
      shoulderTurns = makeup.finalMakeup.shoulderTurns;
    } else if (isLiveDataMode) {
      let shoulderDataPoint = makeup.makeupTelemetry?.find(
        (mt) => mt.shoulderPoint && mt.shoulderPoint !== 0
      );

      if (shoulderDataPoint) {
        shoulderTurns = shoulderDataPoint.turns;
      }
    }

    let shoulderValue: number;

    if (makeup.type === MakeupType.Makeup && shoulderTurns) {
      shoulderValue =
        ((shoulderTurns - xMinDataValue) / (xMaxDataValue - xMinDataValue)) *
        100;
    } else {
      // If it's a breakout we set the shoulder value to 100 so the graph is green
      shoulderValue = 100;
    }

    if (shoulderValue > 100) shoulderValue = 100;
    else if (shoulderValue < 0) shoulderValue = 0;

    shoulderOffset = shoulderValue.toFixed(0) + "%";
  }

  if (isDisplayLast10 && last10Makeups) {
    last10ShoulderOffets = [];

    last10Makeups.forEach((_makeup) => {
      var xMaxValueTemp = _makeup.maxTurns;
      var xMinValueTemp = _makeup.minTurns;
      if (xMaxValueTemp > xMaxValue) xMaxValue = xMaxValueTemp;
      if (xMinValueTemp < xMinValue) xMinValue = xMinValueTemp;

      var yMaxValueTemp = _makeup.maxTorque;
      if (yMaxValueTemp > yMaxValue) yMaxValue = yMaxValueTemp;

      if (_makeup.maxTorque > MaxTorque) MaxTorque = _makeup.maxTorque;
      if (_makeup.refTorque < RefTorque) RefTorque = _makeup.refTorque;

      if (_makeup.finalMakeup && _makeup.finalMakeup.shoulderTurns) {
        var shoulderValue =
          ((_makeup.finalMakeup.shoulderTurns - _makeup.minTurns) /
            (_makeup.maxTurns - _makeup.minTurns)) *
          100;

        if (shoulderValue > 100) shoulderValue = 100;
        else if (shoulderValue < 0) shoulderValue = 0;

        last10ShoulderOffets.push(shoulderValue.toFixed(0) + "%");
      } else {
        last10ShoulderOffets.push("0%");
      }
    });
  }

  if (makeup?.optimumTorque != null) {
    optTorque = makeup.optimumTorque;
  }

  xTicks = Array.from({ length: xTickCount + 1 }, (_, i) =>
    Number((i * ((xMaxValue - xMinValue) / xTickCount) + xMinValue).toFixed(3))
  );

  var yTickFinal =
    Number(
      ((Math.max(yMaxValue, MaxTorque, optTorque) / optTorque) * 10).toFixed(0)
    ) + 2;

  yTicks = Array.from(
    {
      length: yTickFinal,
    },
    (_, i) => Number((i * (optTorque / 10)).toFixed(3))
  );

  yDomainMax = MaxTorque ?? yMaxValue;

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "0.75em",
        padding: "0.5em",
        color: "var(--parker-cyan)",
        height: "calc(100% - 5em - 75px - 70px)",
        width: "calc(100% - 1em)",
      }}
    >
      {isDisplayLast10 ? (
        // Overlay Last 10 Graph
        <OverlayLastTenGraph
          shoulderOffset={shoulderOffset}
          last10Makeups={last10Makeups}
          last10ShoulderOffets={last10ShoulderOffets}
          xTicks={xTicks}
          yTicks={yTicks}
          xMinValue={xMinValue}
          xMaxValue={xMaxValue}
          yDomainMin={yMinValue}
          yDomainMax={yDomainMax}
          maxTorque={makeup?.maxTorque}
          optimumTorque={makeup?.optimumTorque}
          minTorque={makeup?.minTorque}
          maxShoulder={makeup?.maxShoulder}
          minShoulder={makeup?.minShoulder}
          refTorque={makeup?.refTorque}
          ShowRefLineValues={ShowRefLineValues}
          refLineLabelPosition={refLineLabelPosition}
          refLineLabelXOffset={refLineLabelXOffset}
          refLineLabelYOffset={refLineLabelYOffset}
          refLineValuePosition={refLineValuePosition}
          refLineValueXOffset={refLineValueXOffset}
          refLineValueYOffset={refLineValueYOffset}
          xAxisName={xAxisName}
          yAxisName={yAxisName}
        />
      ) : (
        // Primary Graph
        <PrimaryGraph
          makeupTelemetry={makeup?.makeupTelemetry}
          shoulderOffset={shoulderOffset}
          xTicks={xTicks}
          yTicks={yTicks}
          xMinValue={xMinValue}
          xMaxValue={xMaxValue}
          yDomainMin={yMinValue}
          yDomainMax={yDomainMax}
          maxTorque={makeup?.maxTorque}
          optimumTorque={makeup?.optimumTorque}
          minTorque={makeup?.minTorque}
          maxShoulder={makeup?.maxShoulder}
          minShoulder={makeup?.minShoulder}
          refTorque={makeup?.refTorque}
          ShowRefLineValues={ShowRefLineValues}
          refLineLabelPosition={refLineLabelPosition}
          refLineLabelXOffset={refLineLabelXOffset}
          refLineLabelYOffset={refLineLabelYOffset}
          refLineValuePosition={refLineValuePosition}
          refLineValueXOffset={refLineValueXOffset}
          refLineValueYOffset={refLineValueYOffset}
          xAxisName={xAxisName}
          yAxisName={yAxisName}
          displayMode={displayMode}
        />
      )}
    </Box>
  );
};
