import { Http } from "../Utilities/Http";
import { Response } from "../Interfaces/Response";
import { FinalMakeup } from '../Interfaces/FinalMakeup';

const getMatchingFinalMakeups = async (jobId: string, makeupNumber: number, graphNumber: number): Promise<Response<FinalMakeup[]>> => {
	const res = await Http.GET(`/FinalMakeup/${jobId}/${makeupNumber}/${graphNumber}`);
	return res.data;
};

export const FinalMakeupService = {
	getMatchingFinalMakeups,
};