import { useMutation } from "react-query";
import { MakeupTelemetryService } from '../Services/MakeupTelemetryService';

export const useGetMatchingTelemetry = () => {
	const { mutate, data, isError, isSuccess, isLoading } = useMutation(({ jobId, makeupNumber, graphNumber }:
		{ jobId: string, makeupNumber: number, graphNumber: number }) => MakeupTelemetryService.getMatchingTelemetry(jobId, makeupNumber, graphNumber), {
		retry: false
	});

	return { mutate, data, isError, isSuccess, isLoading };
};