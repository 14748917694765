import { SxProps, Theme, Tooltip } from "@mui/material";
import { ReactElement } from "react";

export const CustomTooltip = ({
  title,
  children,
  sx,
}: {
  title: string;
  children: ReactElement<any, any>;
  sx?: SxProps<Theme> | undefined;
}) => {
  return (
    <Tooltip
      placement='top'
      arrow
      title={title}
      sx={{ ...sx }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "var(--parker-blue)",
          },
        },
        arrow: {
          sx: {
            color: "var(--parker-blue)",
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -15],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
};
