import { useMutation } from "react-query";
import { FinalMakeupService } from '../Services/FinalMakeupService';

export const useGetMatchingFinalMakeups = () => {
	const { mutate, data, isError, isSuccess, isLoading } = useMutation(({ jobId, makeupNumber, graphNumber }:
		{ jobId: string, makeupNumber: number, graphNumber: number }) => FinalMakeupService.getMatchingFinalMakeups(jobId, makeupNumber, graphNumber), {
		retry: false
	});

	return { mutate, data, isError, isSuccess, isLoading };
};