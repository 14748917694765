import { Box } from "@mui/material";
import { PageHeader } from "./PageHeader";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { DecisionModal } from "./DecisionModal";

export const MainLayout = () => {
  // Enforce the use of a hash router
  useEffect(() => {
    const loc = window.location;
    if (!loc.href.includes(`${loc.origin}/#/`)) {
      loc.href = `${loc.origin}/#/`;
    }
  }, []);

  return (
    <Box>
      <PageHeader />
      <Outlet />
      <DecisionModal />
    </Box>
  );
};
