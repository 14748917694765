import { Box, Typography } from "@mui/material";
import { Job } from "../Interfaces/Job";
import { ToGUIDSignature } from "../Utilities/GUIDUtility";
import { v4 } from "uuid";
import { CustomTooltip } from "./CustomTooltip";

export const JobDetails = ({ job }: { job: Job | null }) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* Job Details */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
          marginBottom: "1em",
          width: "calc(100% - 1em)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
            variant='h6'
          >
            Job Details
          </Typography>
        </Box>

        {/* Propeties & Values */}
        <Box>
          {/* Job Id */}
          <Box sx={{ display: "flex", margin: "0.4em 0", width: "100%" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Job Id:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <CustomTooltip title={job?.id ?? "-"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {ToGUIDSignature(job?.id)}
                </Typography>
              </CustomTooltip>
            </Box>
          </Box>

          {/* Customer */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Customer:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <CustomTooltip title={job?.customerName ?? "-"}>
                <Typography>{job?.customerName ?? "-"}</Typography>
              </CustomTooltip>
            </Box>
          </Box>

          {/* Location */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Location:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <CustomTooltip title={job?.location ?? "-"}>
                <Typography>{job?.location ?? "-"}</Typography>
              </CustomTooltip>
            </Box>
          </Box>

          {/* Well Number */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Well Number:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <CustomTooltip title={job?.wellNumber ?? "-"}>
                <Typography>{job?.wellNumber ?? "-"}</Typography>
              </CustomTooltip>
            </Box>
          </Box>

          {/* Date */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Date:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <Typography>
                {(job?.startDate &&
                  new Date(job.startDate).toLocaleDateString()) ??
                  "-"}
              </Typography>
            </Box>
          </Box>

          {/* Time */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Time:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <Typography>
                {(job?.startDate &&
                  new Date(job.startDate).toLocaleTimeString()) ??
                  "-"}
              </Typography>
            </Box>
          </Box>

          {/* Company Rep */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Company Rep:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <CustomTooltip title={job?.companyRep ?? "-"}>
                <Typography>{job?.companyRep ?? "-"}</Typography>
              </CustomTooltip>
            </Box>
          </Box>

          {/* Job Details */}
          <Box sx={{ display: "flex", margin: "0.4em 0" }}>
            {/* Name */}
            <Box
              sx={{
                display: "flex",
                width: "42%",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: "0 0.4em 0 0",
                  fontWeight: "bold",
                }}
              >
                Job Details:
              </Typography>
            </Box>

            {/* Value */}
            <Box
              sx={{
                display: "flex",
                width: "58%",
                justifycontent: "left",
                alignItems: "center",
              }}
            >
              <CustomTooltip title={job?.jobDetails ?? "-"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {job?.jobDetails ?? "-"}
                </Typography>
              </CustomTooltip>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Crew Details */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "0.75em",
          padding: "0.5em",
          color: "var(--parker-cyan)",
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
            variant='h6'
          >
            Crew Details
          </Typography>
        </Box>

        <Box>
          {/* Supervisor */}
          <Box sx={{ margin: "0.4em 0" }}>
            <Typography sx={{ fontWeight: "bold" }}>Supervisor:</Typography>
            {(job?.supervisors &&
              job.supervisors.map((supervisor) => {
                return (
                  <Typography key={v4()} sx={{ padding: "0 1em" }}>
                    {supervisor.name}
                  </Typography>
                );
              })) ?? <Typography sx={{ padding: "0 1em" }}>-</Typography>}
          </Box>

          {/* Operators */}
          <Box sx={{ margin: "0.4em 0" }}>
            <Typography sx={{ fontWeight: "bold" }}>Operators:</Typography>
            {(job?.operators &&
              job.operators.map((operator) => {
                return (
                  <Typography key={v4()} sx={{ padding: "0 1em" }}>
                    {operator.name}
                  </Typography>
                );
              })) ?? <Typography sx={{ padding: "0 1em" }}>-</Typography>}
          </Box>

          {/* Inspectors */}
          <Box sx={{ margin: "0.4em 0" }}>
            <Typography sx={{ fontWeight: "bold" }}>Inspectors:</Typography>
            {(job?.inspectors &&
              job.inspectors.map((inspector) => {
                return (
                  <Typography key={v4()} sx={{ padding: "0 1em" }}>
                    {inspector.name}
                  </Typography>
                );
              })) ?? <Typography sx={{ padding: "0 1em" }}>-</Typography>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
